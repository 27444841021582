import React from "react";
import Page from "../components/Page";
import Block from "../components/Block";
import ContentSwitcher from "../components/ContentSwitcher";
import content from "../locales/de/content";

const Home = () => {
  return (
    <Page withStage>
      <Block content={content.intro} prefix="intro" type="intro" />
      <Block content={content.introFollowUp} prefix="introFollowUp" />
      <ContentSwitcher content={content.contentSwitcher} />
      <Block content={content.process} prefix="process" type="prc" theme="grey"/>
      <Block content={content.overview} prefix="overview"/>
      <Block content={content.contact} prefix="contact" type="cta" theme="grey" />
    </Page>
  );
};

export default Home;