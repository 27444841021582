import React, {createContext, useContext, useMemo} from "react";
import { gradientList } from "../utils/theme";
import PropTypes from "prop-types";

export const StylesContext = createContext({
  gradient: `linear-gradient(90deg, ${gradientList[0].gradient[0]}, ${gradientList[0].gradient[1]}, ${gradientList[0].gradient[2]}`,
  gradientProcess: `linear-gradient(90deg, ${gradientList[0].gradientProcess[0]}, ${gradientList[0].gradientProcess[1]}, ${gradientList[0].gradientProcess[2]}`
});

export const StylesContextProvider = ({ children }) => {
  const isSafari = navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") === -1;
  // const isSafariIOs = isSafari && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const ffVersion = navigator.userAgent.match(/Firefox\/(\d*)/);
  const isFirefoxGTE91 = ffVersion && ffVersion[1] && parseInt(ffVersion[1]) >= 91;

  const gradientColors = useMemo(() => {
    const rnd = Math.random();
    const cnt = gradientList.length;
    const index = Math.ceil(rnd * cnt) - 1;
    return gradientList[index];
  }, []);

  return (
    <StylesContext.Provider
      value={{
        gradient: `linear-gradient(90deg, ${gradientColors.gradient[0]}, ${gradientColors.gradient[1]}, ${gradientColors.gradient[2]})`,
        gradientProcess: `linear-gradient(90deg, ${gradientColors.gradientProcess[0]}, ${gradientColors.gradientProcess[1]}, ${gradientColors.gradientProcess[2]})`,
        gradientColors,
        isSafari,
        isFirefoxGTE91
      }}
    >
      {children}
    </StylesContext.Provider>
  );
};

StylesContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useStyles = () => {
  return useContext(StylesContext);
};
