import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { fontSize, fontWeight } from "../utils/theme";

const Comp = styled.div(({ styles }) => ({
  fontSize: fontSize.d30x,
  fontWeight: fontWeight.text,
  lineHeight: "30px",
  ...styles
}));

function ContentFilterTextWrapper({ styles, children }) {
  const { resolutionCoarse } = useViewerClient();
  return (
    <Comp resolutionCoarse={resolutionCoarse} styles={styles}>
      {children}
    </Comp>
  );
}

export default ContentFilterTextWrapper;
