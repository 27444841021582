import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { fontFamily, fontStyleH2Large } from "../utils/theme";

const Comp= styled.h2(({ resolutionCoarse }) => {
  return {
    marginBlockEnd: "20px",
    marginBlockStart: "20px",
    fontFamily: fontFamily.normal,
    ...fontStyleH2Large[resolutionCoarse]
  }
});

function H2Large({ children }) {
  const { resolutionCoarse } = useViewerClient();
  return (
    <Comp resolutionCoarse={resolutionCoarse} >{ children }</Comp>
  );
}

export default H2Large;
