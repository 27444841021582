import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { fontFamily, fontStyleH3 } from "../utils/theme";

const Comp= styled.h3(({ resolutionCoarse }) => {
  return {
    marginBlockEnd: 0, // `${vSpacing.headline[resolutionCoarse]}px`,
    marginBlockStart: 0, // `${vSpacing.headline[resolutionCoarse]}px`,
    fontFamily: fontFamily.normal,
    marginBottom: '11px',
    ...fontStyleH3[resolutionCoarse]
  }
});

function H3({ children }) {
  const { resolutionCoarse } = useViewerClient();
  return (
    <Comp resolutionCoarse={resolutionCoarse} >{ children }</Comp>
  );
}

export default H3;
