const content = {
  contact: [
    {
      component: 'Contact',
    },
  ],
  contactFaq: [
    {
      component: 'Contact',
      params: {
        headlineKey: 'headlineFAQ',
        hideFaqLink: true,
        subHeadlineKey: 'subheadlineFAQ',
      },
    },
  ],
  dataProtection: [
    {
      headline:
        'Informationen zum Datenschutz nach der EU Datenschutz-Grundverordnung (DS-GVO)',
      columns: [
        {
          headline: 'Datenschutzerklärung',
          paragraphs: [
            {
              text: '<p>In einem Verwaltungsverfahren sind Daten personenbezogen, wenn sie einer natürlichen Person zugeordnet werden können. Keine personenbezogenen Daten sind anonymisierte Daten.<br><br>Die Daten werden nur für den Zweck verwendet, für den sie erhoben wurden. Eine Weitergabe an Dritte erfolgt nicht.<br><br>Im Folgenden informieren wir Sie darüber, welche personenbezogenen Daten wir erheben, bei wem wir sie erheben und was wir mit diesen Daten machen. Außerdem informieren wir Sie über Ihre Rechte in Datenschutzfragen und an wen Sie sich diesbezüglich wenden können.<br>In einem Verwaltungsverfahren sind Daten personenbezogen, wenn sie einer natürlichen Person zugeordnet werden können. Keine personenbezogenen Daten sind anonymisierte Daten.</p>',
            },
          ],
        },
      ],
    },
    {
      type: 'accordion',
      sections: [
        {
          headline: 'Wer sind wir?',
          anchor: 'wer',
          paragraphs: [
            {
              text: '<p>Das Portal Kulturfonds Energie des Bundes wird von der Freien und Hansestadt Hamburg betrieben und bietet einen sicheren Zugang zur Beantragung von Fördermaßnahmen von Kultureinrichtungen und für Kulturveranstaltende.</p>',
            },
            {
              text: '<p>Damit wir die gewünschten Leistungen für Sie erbringen können, ist es wie bei vielen Online-Diensten, erforderlich, dass Sie Ihre Daten zur Verfügung stellen. Wir verarbeiten diese Daten nur im Rahmen Ihres Auftrags und verwenden sie nur unter Beachtung der gesetzlichen Bestimmungen (Onlinezugangsgesetz (OZG), Hamburgisches Datenschutzgesetz, EU Datenschutzgrundverordnung (DSGVO), Telekommunikationsgesetz, Telemediengesetz sowie einschlägige Fachgesetze wie beispielsweise das Meldegesetz).</p>',
            },
          ],
        },
        {
          headline: 'Wer sind Ihre Ansprechpartner?',
          anchor: 'ansprechpartner',
          paragraphs: [
            {
              text: '<p>Fragen in datenschutzrechtlichen Angelegenheiten können Sie an das Amt Behördenmanagement der Behörde für Kultur und Medien, vertreten durch die Amtsleitung, richten: </p><ul><li>Behörde für Kultur und Medien<br/>Amt Behördenmanagement<br/>Große Bleichen 30<br/>20354 Hamburg</li><li>Telefon: (040) 428 24 – 238 </li><li>E-Mail: <a href="mailto:Birthe.becker@bkm.hamburg.de">Birthe.becker@bkm.hamburg.de</a></li></ul>',
            },
            {
              text: '<p>Darüber hinaus können Sie sich an den für das Portal zuständigen Datenschutzbeauftragten der Behörde richten:</p><ul><li>Datenschutzbeauftragter der Behörde für Kultur und Medien<br/>Hohe Bleichen 22<br/>20354 Hamburg</li><li>Telefon: (040) 428 24 – 342 </li><li>E-Mail: <a href="mailto:datenschutz@bkm.hamburg.de">datenschutz@bkm.hamburg.de</a></li></ul>',
            },
            {
              text: '<p>Falls Sie Fragen zur Registrierung oder Anmeldung haben oder uns Ihre Anregungen oder Hinweise mitteilen möchten, nehmen Sie bitte unter der E-Mail-Adresse <a href="mailto:service@kulturfonds-energie.de">service@kulturfonds-energie.de</a> bzw. über die zentrale Hotline unter Telefon <a href="tel:+498006645685">0800 6645685</a> Kontakt zu uns auf. <ul><li>E-Mail: <a href="mailto:service@kulturfonds-energie.de">service@kulturfonds-energie.de</a></li><li>Telefon <a href="tel:+498006645685">0800 6645685</a></li></ul></p>',
            },
          ],
        },
        {
          headline:
            'Warum und zu welchem Zweck verarbeiten wir Ihre personenbezogenen Daten?',
          anchor: 'zweck',
          paragraphs: [
            {
              text: '<p>Wir verarbeiten Ihre personenbezogenen Daten, damit Sie über das Portal die mögliche Förderung von Kultureinrichtungen und für Kulturveranstaltende online in Anspruch nehmen können (Verarbeitungszweck).</p>',
            },
            {
              text: '<p>Im Portal werden Ihre Daten zunächst zur Einrichtung eines Benutzerkontos verarbeitet. Ein Benutzerkonto ist zur Nutzung der Plattform erforderlich. Ihre einmalige Einwilligung ist grundlegend für die Verarbeitung Ihrer Daten.</p>',
            },
          ],
        },
        {
          headline: 'Welche personenbezogenen Daten verarbeiten wir?',
          anchor: 'welche',
          paragraphs: [
            {
              text: '<p>Ihre personenbezogenen Daten verifizieren Sie anhand Ihres ELSTER Zuganges und übernehmen diese auf die Plattform. Wir verarbeiten insbesondere folgende personenbezogene Daten für Ihr Benutzerkonto und Ihre beantragte Förderung:</p><ol><li>Vorname</li><li>Nachname</li><li>E-Mail-Adresse</li><li>Passwort</li><li>Adresse</li><li>Bankverbindung</li><ol>',
            },
            {
              headline: 'Wichtiger Hinweis zum Passwort',
              text: '<p>Halten Sie Ihr Passwort geheim, damit keine unberechtigten Personen Zugang zu Ihren Daten erlangen. Geben Sie Ihr Passwort nicht auf Nachfrage bekannt. Auch die technischen Betreuer des Kulturfonds Energie des Bundes sind nicht berechtigt, Ihr Passwort zu erfahren.</p>',
            },
          ],
        },
        {
          headline: 'Wie verarbeiten wir diese Daten?',
          anchor: 'wie',
          paragraphs: [
            {
              text: '<p>Ihre personenbezogenen Daten werden gespeichert. Sofern Sie an der Plattform angemeldet sind, werden die Daten aus Ihrem Benutzerkonto dazu genutzt, Formularfelder vorauszufüllen, so dass Sie diese Daten nicht erneut eingeben müssen.</p>',
            },
            {
              text: '<p>Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre personenbezogenen Daten gegen unbeabsichtigte oder unrechtmäßige Vernichtung, Verlust oder Veränderung sowie gegen unbefugte Offenlegung oder unbefugten Zugang zu schützen. Unsere Sicherheitsstandards entsprechen stets den aktuellen technologischen Entwicklungen.</p>',
            },
          ],
        },
        {
          headline:
            'Unter welchen Voraussetzungen dürfen wir Ihre Daten an Dritte weitergeben?',
          anchor: 'voraussetzungen',
          paragraphs: [
            {
              text: '<p>Ihre persönlichen Daten werden nicht an Dritte weitergegeben.</p>',
            },
          ],
        },
        {
          headline: 'Wie lange speichern wir Ihre Daten?',
          anchor: 'dauer',
          paragraphs: [
            {
              headline: 'Benutzerkonto',
              text: '<p>Wir speichern Ihre Daten in Ihrem Benutzerkonto, solange Sie es nutzen. Nach Ablauf der Fördermaßname Kulturfonds Energie des Bundes wird die Plattform stillgelegt und Ihre Daten gelöscht.</p>',
            },
          ],
        },
        {
          headline:
            'Welche Rechte (Auskunftsrecht, Widerspruchsrecht usw.) haben Sie?',
          anchor: 'rechte',
          paragraphs: [
            {
              text: '<p>Sie haben nach der Datenschutz-Grundverordnung verschiedene Rechte. Einzelheiten ergeben sich insbesondere aus Artikel 15 bis 18 und 21 der Datenschutz-Grundverordnung.</p>',
            },
            {
              headline: 'Recht auf Auskunft',
              text: '<p>Sie können Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten verlangen. In Ihrem Auskunftsantrag sollten Sie Ihr Anliegen präzisieren, um uns das Zusammenstellen der erforderlichen Daten zu erleichtern. Daher sollten in dem Antrag möglichst Angaben zum konkreten Verwaltungsverfahren und zum Verfahrensabschnitt gemacht werden.</p>',
            },
            {
              headline: 'Recht auf Berichtigung',
              text: '<p>Sollten die Sie betreffenden Angaben nicht (mehr) zutreffend sein, können Sie eine Berichtigung verlangen. Sollten Ihre Daten unvollständig sein, können Sie eine Vervollständigung verlangen.</p>',
            },
            {
              headline: 'Recht auf Löschung',
              text: '<p>Sie können die Löschung Ihrer personenbezogenen Daten verlangen. Ihr Anspruch auf Löschung hängt u.&nbsp;a. davon ab, ob die Sie betreffenden Daten von uns zur Erfüllung unserer gesetzlichen Aufgaben noch benötigt werden.</p>',
            },
            {
              text: '<p>Ist dies nicht erforderlich, erfolgt die Löschung Ihrer Daten unverzüglich.</p>',
            },
            {
              headline: 'Recht auf Einschränkung der Verarbeitung',
              text: '<p>Sie haben das Recht, eine Einschränkung der Verarbeitung der Sie betreffenden Daten zu verlangen. Die Einschränkung steht einer Verarbeitung nicht entgegen, soweit an der Verarbeitung ein wichtiges öffentliches Interesse besteht.</p>',
            },
            {
              headline: 'Recht auf Widerspruch',
              text: '<p>Sofern Ihre Daten nicht auf der Grundlage Ihrer Einwilligung, sondern aus Gründen eines überwiegenden öffentlichen Interesses oder einer Rechtsvorschrift verarbeitet werden, haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit der Verarbeitung der Sie betreffenden Daten zu widersprechen.</p>',
            },
            {
              headline: 'Recht auf Beschwerde',
              text: '<p>Wenn Sie der Auffassung sind, dass wir Ihrem Anliegen nicht oder nicht in vollem Umfang nachgekommen sind, können Sie bei der zuständigen Datenschutzaufsichtsbehörde Beschwerde einlegen:</p><ul><li>Der Hamburgische Beauftragte für Datenschutz und Informationsfreiheit<br/>Ludwig-Erhard-Str 22, 7. OG<br/>20459 Hamburg</li><li>Telefon: (040) 428 54 - 4040</li><li>E-Fax: (040) 4279 – 11811</li><li>E-Mail: <a href="mailto:mailbox@datenschutz.hamburg.de">mailbox@datenschutz.hamburg.de</a></li></ul>',
            },
            {
              headline: 'Allgemeine Hinweise zu diesen Rechten',
              text: '<p>In einigen Fällen können oder dürfen wir Ihrem Anliegen nicht entsprechen. Sofern dies gesetzlich zulässig ist, teilen wir Ihnen in diesem Fall immer den Grund für die Verweigerung mit.</p>',
            },
            {
              text: '<p>Wir werden Ihnen aber grundsätzlich innerhalb eines Monats nach Eingang Ihres Anliegens antworten. Sollten wir länger als einen Monat für eine abschließende Klärung brauchen, erhalten Sie eine Zwischennachricht.</p>',
            },
          ],
        },
        {
          headline:
            'Informationen zu Cookies und Messverfahren zur Nutzung der Antragsplattform Sonderfonds des Bundes für Messen und Ausstellungen',
          anchor: 'cookies',
          paragraphs: [
            {
              headline: 'Cookies',
              text: '<p>Cookies sind Datenelemente, die eine Website an Ihren Browser schickt, um sie auf Ihrem System zu speichern. Wir benutzen technisch notwendige "Sitzungs-Cookies", um Informationen aus der Website an Ihre Bedürfnisse anpassen zu können. Sobald Sie sich abmelden, werden die Cookies gelöscht.</p>',
            },
            {
              text: '<p>Die meisten Browser können Sie so einstellen, dass Sie benachrichtigt werden, wenn Sie Cookies erhalten. Sie können dann selbst entscheiden, ob Sie das akzeptieren wollen oder nicht. Um die Plattform nutzen zu können, müssen Sie diese Sitzungs-Cookies zulassen.</p>',
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          paragraphs: [
            {
              text: '<p>Stand 15.&nbsp;Februar&nbsp;2023</p>',
            },
          ],
        },
      ],
    },
  ],
  faqIntro: [
    {
      pageHeadline: 'Häufig gestellte Fragen',
      abstract:
        'Hier finden Sie Antworten und detaillierte Informationen zum Kulturfonds Energie des Bundes. Eine Druckfunktion bietet darüber hinaus die Möglichkeit den aktuellen Stand der FAQ zu dokumentieren.',
    },
  ],
  faqFilter: [
    {
      component: 'ContentFilter',
      params: {
        id: 'faq',
      },
    },
  ],
  faqAccordion: [
    {
      headline: 'FAQ',
      headlineComponent: 'H2Large',
      type: 'accordion',
      id: 'faq',
    },
  ],
  implementation: [
    {
      pageHeadline: 'Vollzugshinweise',
      abstract:
        'Vollzugshinweise für die Gewährung von Entlastung als Billigungsleistung für Kultureinrichtungen und Kulturveranstaltende (Kulturfonds Energie des Bundes)',
    },
    {
      id: 'implementation',
    },
  ],
  intro: [
    {
      headline: 'Wichtige Informationen',
      columns: [
        {
          paragraphs: [
            {
              text:
      
        'Der Kulturfonds Energie des Bundes wird als Härtefallhilfe aus dem Wirtschaftsstabilisierungsfonds-Energie (WSF-E) finanziert. Dieser endet infolge des Bundesverfassungsgerichtsurteils vom 15.11.2023 zum Jahresende. Mit Auslaufen des WSF-E endet auch der Kulturfonds Energie des Bundes. Die 4. und 5. Fördertranche, deren Antragszeitraum im Jahr 2024 gelegen hätte, müssen daher entfallen.' +
        '<br><br>' +
        'Um den Kultureinrichtungen bzw. Kulturveranstaltenden, die bis zum <b>21.11.2023</b> (Datum der Haushaltssperre gemäß § 41 BHO) einen Antrag gestellt haben, Vertrauensschutz zu gewähren, haben sich Bund und Länder darauf verständigt, dass die bis einschließlich 21.11.2023 eingereichten Anträge im Kulturfonds Energie des Bundes auf der Grundlage der vorliegenden Angaben der Antragstellenden von den Bewilligungsstellen der Länder soweit möglich final, soweit nötig zunächst vorläufig verbeschieden und die beantragten Summen ausgezahlt werden.' +
        '<br><br>' +
        'Bei nur vorläufigen Bescheiden werden die Anträge nach Auszahlung von den Bewilligungsstellen der Länder im Nachgang abschließend geprüft. Dabei kann es ggfs. zu Rückforderungen kommen.' +
        '<br><br>' +
        'Anträge von Kultureinrichtungen und Kulturveranstaltenden, die nach dem 21.11.2023 gestellt wurden, können aufgrund der o. g. Umstände hinsichtlich der <b>Bundesförderung</b> nicht mehr positiv verbeschieden werden.'
            }
          ]
        }
      ]
    },
    /*{
      pageHeadline: 'Steigende Energiekosten abfedern',
      abstract:
        'Kultureinrichtungen und Kulturveranstaltungen sind wichtige soziale Orte. Sie sind von zentraler Bedeutung für die kulturelle Bildung und den gesellschaftlichen Austausch. Steigende Energiepreise gefährden den Erhalt von Kulturangeboten wie beispielsweise Kinos, Theatern, Konzerten, Museen, Bibliotheken und Archiven. Aus diesem Grund haben Bund und Länder den Kulturfonds Energie des Bundes entwickelt.' +
        '<br>' +
        'Mit dem Kulturfonds Energie des Bundes bietet der Bund zusätzlich zu den allgemeinen Entlastungsmaßnahmen gezielte Unterstützung in Höhe von bis zu 1 Milliarde Euro für den Kulturbereich zur Bewältigung der hohen Energiekosten.',
    },
    {
      columns: [
        {
          paragraphs: [
            {
              text: '<p>Der Fonds gleicht anteilig den Mehrbedarf zur Deckung der Energiekosten für Gas, Fernwärme und netzbezogenen Strom aus. Bei der Berechnung der Fördersumme werden die Wirkungen der Preisbremsen und das allgemeine Einsparziel von mindestens 20 % im Vergleich zum Durchschnittsverbrauch vor der Krise berücksichtigt - der konkrete Nachweis einer bestimmten Einsparleistung wird jedoch nicht vorausgesetzt.</p>',
            },
          ],
        },
        {
          paragraphs: [
            {
              text: '<p> Der Förderzeitraum erstreckt sich rückwirkend vom 1. Januar 2023 bis zum 30. April 2024. Dieses vom Bund finanzierte und von Bund und Ländern entwickelte Hilfsprogramm baut auf den bereits erprobten Strukturen des Sonderfonds des Bundes für Kulturveranstaltungen auf. Die Bearbeitung der Förderanträge erfolgt durch die örtlich zuständigen Länder.</p>',
            },
          ],
        },
      ],
    },*/
    /*{
      columns: [
        {
          paragraphs: [
            {
              text: '<p>Die Förderung unterscheidet zwei Fallgruppen: <strong>Kultureinrichtungen</strong> und <strong>Kulturveranstaltende von Einzelveranstaltungen</strong> in geschlossenen Räumen:</p>',
            },
          ],
        },
      ],
    }*/
  ],
  introFollowUp: [
    {
      columns: [
        {
          paragraphs: [
            {
              text: '<p>Die Förderung unterscheidet zwei Fallgruppen: <strong>Kultureinrichtungen</strong> und <strong>Kulturveranstaltende von Einzelveranstaltungen</strong> in geschlossenen Räumen:</p>',
            },
          ],
        },
      ],
    }
  ],
  legal: [
    {
      headline: 'Impressum',
      columns: [
        {
          paragraphs: [
            {
              headline:
                'Die Freie und Hansestadt Hamburg ist eine Körperschaft des öffentlichen Rechts<br/>Die Freie und Hansestadt Hamburg wird vertreten durch:',
              text: '<p>Erster Bürgermeister Dr. Peter Tschentscher<br/>Rathausmarkt 1<br/>20095 Hamburg</p>',
            },
            {
              headline: 'Inhaltlich für diese Webseite verantwortlich:',
              text: '<p>Leiter der Pressestelle der Behörde für Kultur und Medien<br/>Enno Isermann<br/>Hohe Bleichen 22<br/>20354 Hamburg</p>',
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          headline: 'Kontakt',
          paragraphs: [
            {
              text: '<p>Sollten Probleme mit der Registrierung oder der Anmeldung auftreten oder Sie Fragen, Anregungen oder Hinweise zur Plattform „Sonderfonds des Bundes für Kulturveranstaltungen“ allgemein haben, nehmen Sie bitte unter der E-Mail-Adresse<a href="mailto:service@kulturfonds-energie.de">service@kulturfonds-energie.de</a> Kontakt bzw. über die zentrale Hotline unter Telefon <a href="tel:+498006645685">0800 6645685</a> zu uns auf.</p>',

            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          headline: 'Urheberrecht',
          paragraphs: [
            {
              text: '<p>Das Layout der Homepage, die verwendeten Grafiken, die einzelnen Beiträge sowie die Sammlung der Beiträge sind urheberrechtlich geschützt. Die Seiten dürfen nur zum privaten Gebrauch vervielfältigt, Änderungen nicht vorgenommen und Vervielfältigungsstücke ohne Genehmigung nicht verbreitet werden.</p>',
            },
          ],
        },
      ],
    },
  ],
  overview: [
    {
      headline: 'Auf einen Blick',
      headlineComponent: 'H3Large',
      anchor: 'ueberblick',
      columns: [
        {
          paragraphs: [
            {
              headline: 'Wer kann eine Förderung zu den Energiekosten beantragen?',
              text: '<p>Über den Kulturfonds Energie des Bundes können Kultureinrichtungen, z.B. Theater, Konzerthäuser, Kinos, Museen oder Bibliotheken, sowie Kulturveranstaltende von Einzelveranstaltungen in geschlossenen Räumen eine Unterstützung zur Abfederung der durch die Energiekrise verursachten Härten beantragen. KünstlerInnen individuell sind nicht antragsberechtigt.</p>',
            },
          ],
        },
        {
          paragraphs: [
            {
              headline: 'Welcher Anteil an Energiekosten wird gefördert?',
              text: '<p>Kultureinrichtungen können einen anteiligen Ausgleich zum angefallenen Mehrbedarf an Energiekosten beantragen. Veranstaltende von Kulturveranstaltungen können eine Pauschale, gestaffelt nach der Kapazität des Veranstaltungsraums, zur Abfederung der energiebedingten Mehrkosten für die Anmietung der zur Durchführung genutzten Veranstaltungsräume beantragen.</p>',
            },
          ],
        },
        {
          paragraphs: [
            {
              headline: 'Wie funktioniert die Förderung?',
              text: '<p>Die Förderung kann rückwirkend für den Zeitraum ab dem 1. Januar 2023 beantragt werden: Kultureinrichtungen müssen ihren historischen Verbrauch, die historischen und aktuellen Kosten angeben. Die Energiemehrkosten werden anteilig erstattet. Kulturveranstaltende geben ihre (maximal branchenüblichen)  Mietkosten der angemieteten Veranstaltungsräume an. Sie erhalten als Ausgleich für die Energiemehrkosten einen Festbetrag pro Veranstaltungstag.</p>',
            },
          ],
        },
      ],
    },
  ],
  process: [
    {
      headline: 'Ablauf',
      headlineComponent: 'H3LargeMobileCenter',
      anchor: 'ablauf',
      component: 'Process',
    },
  ],
  contentSwitcher:
    {
      tabs: [{
        title: 'Kultureinrichtungen',
        paragraphs: [
          {
            headline: 'Adressaten der Förderung',
            text: '<p><u>Öffentliche und private Kultureinrichtungen</u>, d.h. Orte, an denen nach Art. 53 Ziff. 2 lit. a) Allgemeine Gruppenfreistellungsverordnung (AGVO) kulturelle Zwecke und Aktivitäten verfolgt werden: <i>„Museen, Archive, Bibliotheken, Kunst- und Kulturzentren oder -stätten, Theater, Kinos, Opernhäuser, Konzerthäuser, sonstige Einrichtungen für Live-Aufführungen, Einrichtungen zur Erhaltung und zum Schutz des Filmerbes und ähnliche Infrastrukturen“</i>. Hierzu zählen auch soziokulturelle Zentren sowie Kultureinrichtungen, für die Kulturelle Bildung im Sinne von Art. 53 Ziff. 2 e) AGVO zu ihren <u>zentralen Aufgaben</u> gehört (z.B. Jugendkunst- und Musikschulen,  Kulturzentren und -vereine); erfasst sind Tätigkeiten im Bereich der kulturellen und künstlerischen Bildung sowie Förderung der Vielfalt kultureller Ausdrucksformen durch Vermittlungs- und Bildungsprogramme.</p>',
          },
          {
            headline: 'Antragsberechtigt',
            text: '<p>Antragsberechtigt sind öffentliche und private Kultureinrichtungen, sofern sie öffentlich zugänglich sind.</p>',
          },
          {
            headline: 'Gegenstand der Förderung',
            text: '<p>Umfasst sind die Mehrkosten für Gas, Fernwärme und netzbezogenen Strom. <br>Die förderfähigen Kosten einer Einrichtung sind die Mehrkosten, die sich aus der Differenz zwischen den jeweils aktuellen Energiekosten (unter den Bedingungen der Preisbremsen) für 80 % des historischen Verbrauchs und den historischen Kosten für 100 % des historischen Verbrauchs ergeben. Der verbrauchsunabhängige Grundpreis wird hierbei nicht berücksichtigt. Die förderfähigen Energiemehrkosten werden anteilig erstattet. Der Kulturfonds Energie bezuschusst die förderfähigen Mehrkosten bei öffentlichen Einrichtungen als maximale Förderquote in Höhe des regulären Bundesanteils, mindestens aber zu 50 %, und bei den privaten Einrichtungen und soziokulturellen Zentren zu 80 %.</p>',
          },
        ],
      },
      {
        title: 'Kulturveranstaltende',
        paragraphs: [
          {
            headline: 'Adressaten der Förderung',
            text: '<p><u>Kulturveranstaltende</u> nach Art. 53 Ziff. 2 lit. d) AGVO: Veranstaltende von <i>„Veranstaltungen und Aufführungen im Bereich Kunst und Kultur, Festivals, Ausstellungen und ähnliche kulturelle Aktivitäten“</i> als ticketbasierte Einzelveranstaltungen in geschlossenen Räumen.</p>',
          },
          {
            headline: 'Antragsberechtigt',
            text: '<p>Antragsberechtigt sind Kulturveranstaltende, wenn sie ticketbasierte Kulturveranstaltungen in geschlossenen Räumen durchführen, die nicht selbst als Kultureinrichtung i.S.d. Kulturfonds Energie des Bundes gelten und antragsberechtigt sind. </p>',
          },
          {
            headline: 'Gegenstand der Förderung ',
            text: '<p>Da Kulturveranstaltende in der Regel Mietverträge ohne ausgewiesene Energiekosten für die genutzten Veranstaltungsräume schließen, werden sie mit einem Festbetrag gestaffelt nach der Kapazität des Veranstaltungsraumes gefördert.</p>',
          }
        ]
      }
    ]
  }
};

export default content;
