import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { useViewerClient } from "../context/viewerClient";
import { useVersionedContent } from "../context/versionedContent";
import { enrichRegEx } from "../utils/content";
import { colors, colorSchemes, fontSize, fontWeight, maxTextWidth, themeButtonReset } from "../utils/theme";
import CrossIcon from "./CrossIcon";
import InputGroup from "./InputGroup";
import SearchIcon from "./SearchIcon";
import ControlsWrapper from "./ControlsWrapper";
import InputGroupButton from "./InputGroupButton";
import ContentFilterTextWrapper from "./ContentFilterTextWrapper";

const Comp = styled.div({
  maxWidth: maxTextWidth
});

const InputGroupIconWrapper = styled.div({
  flex: "0 0 auto",
  display: "flex",
  alignItems: "center",
  marginLeft: "20px",
  marginRight: "20px"
});

const ClearButton = styled.div({
  ...themeButtonReset,
  flex: "0 0 auto",
  display: "flex",
  alignItems: "center",
  marginLeft: "20px",
  marginRight: "20px"
});

const InputWrapper = styled.form({
  flex: "1 1 100%",
  position: "relative",
  display: "flex"
});

const Input = styled.input(({ isMobile }) => ({
  height: "65px",
  width: "100%",
  padding: "0 20px",
  border: "none",
  fontSize: fontSize.d40x,
  fontWeight: fontWeight.text,
  color: colorSchemes.light.color,
  ":focus": {
    outline: "none"
  },
  fontFamily: "inherit"
}));

const SuggestionList = styled.ul({
  listStyleType: "none",
  ...colorSchemes.light,
  top: "100%",
  position: "absolute",
  margin: "2px 0 0",
  padding: 0,
  fontSize: fontSize.d40x,
  fontWeight: fontWeight.text,
  width: "calc(100% + 60px)",
  boxShadow: `0px 4px 8px ${colors.GREY_MEDIUM}`,
  zIndex: 1,
  maxHeight: "100vh",
  overflowY: "auto"
});

const SuggestionEntry = styled.li(({ focused }) => ({
  height: "53px",
  cursor: "default",
  padding: "0 21px",
  background: focused ? colors.GREY_MEDIUM_LIGHT : "inherit",
  ":hover": {
    background: colors.GREY_MEDIUM_LIGHT
  }
}));

const Highlight = styled.span({
  background: colors.BLUE_MEDIUM
});

const CompareInfo = styled.div({
  marginTop: "3px",
  background: colors.BLUE_MEDIUM,
  paddingLeft: "12px",
  paddingRight: "12px",
  fontSize: fontSize.d20x,
  height: "30px"
});

function ContentFilterSearch({ id }) {
  const { isGTETabletP, resolutionCoarse } = useViewerClient();
  const searchRef = useRef();
  const { t } = useTranslation();
  const { selectedVersions, getSearchOptions, contentLists, search } = useVersionedContent();

  const [searchString, setSearchString] = useState("");
  const [submittedSearchString, setSubmittedSearchString] = useState(null);
  const [options, setOptions] = useState([]);
  const [focusIndex, setFocusIndex] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptionsState] = useState(false);
  const [showSearchButton, setShowButton] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);

  const filterOptions = (newSearchString, opts) => {
    const filterRegex = new RegExp(`(${enrichRegEx(newSearchString)})`, "gi");
    const fOpts = opts
      .filter((value) => value.match(filterRegex))
      .map((value) => ({ value, formatted: value.replace(filterRegex, "<strong>$1</strong>") }));
    setFilteredOptions(fOpts);
  };

  const setShowOptions = useCallback((state) => {
    setFocusIndex(0);
    setShowOptionsState(state);
  }, []);

  const handleOnClear = () => {
    if (searchRef.current) {
      searchRef.current.value = "";
    }
    setSearchString("");
    setSubmittedSearchString(null);
    search(id, null);
    setFilteredOptions([]);
    setShowOptions(false);
    setOptions([]);
    setShowButton(false);
    setShowClearButton(false);
  };

  const handleOnSelectOption = useCallback(
    (newValue) => {
      setShowOptions(false);
      if (searchRef.current) {
        searchRef.current.value = newValue;
        setSubmittedSearchString(newValue);
        search(id, searchRef.current.value);
      }
    },
    [id, search, setShowOptions]
  );

  const handleOnSearch = useCallback(
    (e) => {
      e.preventDefault();
      setShowOptions(false);
      if (searchRef.current) {
        setSubmittedSearchString(searchRef.current.value);
        search(id, searchRef.current.value);
      }
    },
    [id, search, setShowOptions]
  );

  const listener = useCallback(
    (e) => {
      if (e.code === "ArrowDown") {
        if (focusIndex === null) {
          setFocusIndex(0);
        } else {
          setFocusIndex(focusIndex + 1);
        }
      } else if (e.code === "ArrowUp") {
        if (focusIndex === 0) {
          setFocusIndex(null);
        } else if (focusIndex !== null) {
          setFocusIndex(focusIndex - 1);
        }
      } else if (e.code === "Enter") {
        if (filteredOptions && filteredOptions[focusIndex]) {
          handleOnSelectOption(filteredOptions[focusIndex].value);
        }
      }
    },
    [filteredOptions, focusIndex, handleOnSelectOption]
  );

  useEffect(() => {
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    }
  }, [listener]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    if (newValue && newValue.length >= 3) {
      const newSearchString = newValue.substring(0, 3).toLowerCase();
      setSearchString(newSearchString);

      if (!showOptions || searchString !== newSearchString) {
        setShowOptions(true);
        getSearchOptions(id, newSearchString, (opts) => {
          setOptions(opts);
          filterOptions(newValue, opts);
        });
      } else if (options) {
        filterOptions(newValue, options);
      }
      if (!showSearchButton) {
        setShowButton(true);
      }
    } else if (showOptions) {
      setShowOptions(false);
      setOptions([]);
      setFilteredOptions([]);
      if (showSearchButton) {
        setShowButton(false);
      }
    }
    if (newValue && newValue.length >= 1) {
      if (!showClearButton) {
        setShowClearButton(true);
      }
    } else {
      if (showClearButton) {
        handleOnClear();
      }
    }
  };

  const count = (contentLists && contentLists[id]) ? contentLists[id].matchCount : null;
  return (
    <Comp>
      <InputGroup isMobile={!isGTETabletP}>
        <InputGroupIconWrapper>
          <SearchIcon />
        </InputGroupIconWrapper>
        <InputWrapper onSubmit={handleOnSearch}>
          <Input
            aria-label={t("contentFilter.search.inputAria")}
            ref={searchRef}
            type="text"
            placeholder={t(`contentFilter.search.placeholder.${isGTETabletP ? "desktop" : "mobile"}`)}
            onChange={handleInputChange}
            isMobile={!isGTETabletP}
          />
          {showOptions && (
            <SuggestionList aria-label={t("contentFilter.search.suggestionListAria")}>
              {filteredOptions.map(({ value, formatted }, index) => (
                <SuggestionEntry
                  focused={index === focusIndex}
                  key={value}
                  onClick={() => handleOnSelectOption(value)}
                  dangerouslySetInnerHTML={{ __html: formatted }}
                  onMouseEnter={() => setFocusIndex(index)}
                />
              ))}
            </SuggestionList>
          )}
        </InputWrapper>
        {showClearButton && (
          <ClearButton aria-label={t("contentFilter.search.clearAria")} role="button" onClick={handleOnClear}>
            <CrossIcon color={colorSchemes.light.color} width={28} height={28} />
          </ClearButton>
        )}
        {showSearchButton && isGTETabletP && (
          <InputGroupButton onClick={handleOnSearch}>{t("contentFilter.search.action")}</InputGroupButton>
        )}
      </InputGroup>
      {showSearchButton && !isGTETabletP && (
        <InputGroupButton onClick={handleOnSearch} isMobile>
          {t("contentFilter.search.action")}
        </InputGroupButton>
      )}
      {count !== undefined && count !== null && (
        <>
          {count > 0 ? (
            <>
              <ControlsWrapper>
                <CompareInfo>
                  &lt;&nbsp;
                  <Trans i18nKey={`contentFilter.search.count`} count={count}>
                    ...{{ count }}
                  </Trans>
                  &nbsp;&gt;
                </CompareInfo>
              </ControlsWrapper>
              <ContentFilterTextWrapper resolutionCoarse={resolutionCoarse}>
                <Trans i18nKey={`contentFilter.search.intro`}>
                  <strong>Sie durchsuceen </strong> den derzeit aktuellen Stand der FAQ vom{" "}
                  <strong>{{ date: selectedVersions[id][0].label }}.</strong> Es werden nur die Fragen im FAQ angezeigt, in
                  denen Ihre Suchtreffer auftauchen. <Highlight>Das Ergebnis Ihrer Suche</Highlight> wird in den aufgeklappten
                  Antworten der Fragen hervorgehoben.
                </Trans>
              </ContentFilterTextWrapper>
            </>
          ): (
            <>
              <ControlsWrapper>
                <CompareInfo>
                  &lt;&nbsp;
                  <Trans i18nKey={`contentFilter.search.noMatch.summary`} ></Trans>
                  &nbsp;&gt;
                </CompareInfo>
              </ControlsWrapper>
              <ContentFilterTextWrapper resolutionCoarse={resolutionCoarse}>
                <Trans i18nKey={`contentFilter.search.noMatch.intro`}>
                  <strong>Sie durchsuchen </strong> den derzeit aktuellen Stand der FAQ vom{" "}
                  <strong>{{ date: selectedVersions[id][0].label }}.</strong> Ihr Suchbegriff <strong>{{fullSearchString: submittedSearchString}}</strong> wurde nicht im FAQ gefunden. Probieren Sie es mit einem anderen oder allgemeineren Suchbegriff.
                </Trans>
              </ContentFilterTextWrapper>
            </>
          )}
        </>
      )}
    </Comp>
  );
}

export default ContentFilterSearch;
