import ContentFilter from "../components/ContentFilter";
import Contact from "../components/Contact";
import H3 from "../components/H3";
import H3Large from "../components/H3Large";
import H3LargeMobileCenter from "../components/H3LargeMobileCenter";
import H2Large from "../components/H2Large";
import Process from "../components/Process";
import ContentSwitcher from "../components/ContentSwitcher";

import DataProtection from "../pages/DataProtection";
import Faq from "../pages/Faq";
import Home from "../pages/Home";
import Legal from "../pages/Legal";
import SimpleContent from "../pages/SimpleContent";

export const useComponents = () => ({
  ContentFilter,
  Contact,
  DataProtection,
  Faq,
  Home,
  Legal,
  Process,
  ContentSwitcher,
  SimpleContent,
  H3,
  H3Large,
  H3LargeMobileCenter,
  H2Large,
});
