import React from "react";
import styled from "styled-components";

const Comp = styled.div(({ isFirst }) => ({
  flex: `0 ${isFirst ? 1 : 1} auto`,
  display: "flex",
  flexWrap: "wrap",
  svg: {
    maxWidth: "100%",
    marginLeft: isFirst ? 0 : "50px",
    marginRight: isFirst ? 0 : "50px",
  },
  div: {
    marginLeft: "50px"
  }
}));

function SponsorLogoWrapper({ isFirst, children }) {
  return (
    <Comp isFirst={isFirst}>{children}</Comp>
  );
}
export default SponsorLogoWrapper;
