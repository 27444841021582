import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { useVersionedContent } from "../context/versionedContent";
import { useViewerClient } from "../context/viewerClient";
import { textStylesFilterNoControl } from "../utils/theme";
import ContentFilterTextWrapper from "./ContentFilterTextWrapper";
import InputGroup from "./InputGroup";
import InputGroupButton from "./InputGroupButton";

const ButtonWrapper = styled.div({ display: "flex" });

function ContentFilterPrint({ id }) {const { selectedVersions } = useVersionedContent();
  const { isGTETabletP, resolutionCoarse } = useViewerClient();
  const { t } = useTranslation();

  return (
    <>
      <ButtonWrapper>
        <InputGroup styles={{ marginBottom: 0 }}>
          <InputGroupButton onClick={() => window.print()}>{t("contentFilter.print.action")}</InputGroupButton>
        </InputGroup>
      </ButtonWrapper>
      <ContentFilterTextWrapper isMobile={!isGTETabletP} resolutionCoarse={resolutionCoarse} styles={textStylesFilterNoControl}>
        <Trans i18nKey={"contentFilter.print.intro"} date={selectedVersions[id][0].label}>
          <strong>Drucken Sie</strong> den aktuellen Stand des FAQ vom <strong>{{ date: selectedVersions[id][0].label }}</strong> im Volltext und ohne Markierungen aus.
        </Trans>
      </ContentFilterTextWrapper>
    </>
  );
}

export default ContentFilterPrint;
