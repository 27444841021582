

import styled from "styled-components";

const Triangle = styled.div(( { orientation = "top", height = "17px", semiBase = "17px", color }) => {
  const borderSide = `${semiBase} solid transparent`;
  const borderBase = `${height} solid ${color}`;

  const style = {
    flex: `0 0 ${height}`
  };
  if (orientation === "top" || orientation === "topLeft") {
    if (orientation === "topLeft") {
      style.margin = "0 5% 0 auto";
    } else {
      style.margin = "0 auto";
    }
    style.borderLeft = borderSide;
    style.borderRight = borderSide;
    style.borderTop = borderBase;
  } else if (orientation === "bottom" || orientation === "bottomLeft") {
    if (orientation === "bottomLeft") {
      style.margin = "0 5% 0 auto";
    } else {
      style.margin = "0 auto";
    }
    style.borderLeft = borderSide;
    style.borderRight = borderSide;
    style.borderBottom = borderBase;
  } else if (orientation === "left") {
    style.margin = "auto 0";
    style.borderTop = borderSide;
    style.borderBottom = borderSide;
    style.borderLeft = borderBase;
    style.marginRight = `-${height}`;
  } else {
    style.margin = "auto 0";
    style.borderTop = borderSide;
    style.borderBottom = borderSide;
    style.borderRight = borderBase;
    style.marginLeft = `-${height}`;
  };
  return style;
});

export default Triangle;