import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { hSpacing } from "../utils/theme";
import Column from "./Column";


const Comp = styled.div(({ resolutionCoarse, showInColums, twoColumnsGTEDesktop }) => {
  if (showInColums) {
    return {
      marginLeft: `-${hSpacing.colGap[resolutionCoarse] / 2}px`,
      marginRight: `-${hSpacing.colGap[resolutionCoarse] / 2}px`,
      display: "flex",
      justifyContent: "space-between",
      maxWidth: twoColumnsGTEDesktop ? `${hSpacing.maxTextWidths + hSpacing.colGap.desktop}px` : "none"
    };
  }
  return {};
});

/**
 *
 * @param {object} columns columns object
 * @returns
 */
 const ColumnsWrapper = ({ columns }) => {
  const { resolutionCoarse, isGTETabletP, isGTEDesktop } = useViewerClient();

  const columnStyles = {};
  const oneColumn = columns && (columns.length === 1);
  const showInColums = columns && ((columns.length === 2 && isGTETabletP) || (columns.length >= 3 && isGTEDesktop));
  const twoColumnsGTEDesktop = columns && (columns.length === 2 && isGTEDesktop);

  if(!showInColums) {
    // add margin if columns are wrapped
    columnStyles.marginBottom = "2.2em";
    if (isGTETabletP) {
      // max width for single col text
      columnStyles.maxWidth = "730px";
    }
  }

  if (oneColumn) {
    columnStyles.marginBottom = "-12px";
    columnStyles.maxWidth = `${hSpacing.maxTextWidth[resolutionCoarse] - hSpacing.indent[resolutionCoarse].right}px`;
  }

  return (
    <Comp
      resolutionCoarse={resolutionCoarse}
      showInColums={showInColums}
      twoColumnsGTEDesktop={twoColumnsGTEDesktop}
    >
      {columns.map((column, index) => (
        <Column
          showInColums={showInColums}
          count={columns.length}
          key={`col_${index}`}
          content={column}
          columnStyles={columnStyles}
        />
      ))}
    </Comp>
  );
};

export default ColumnsWrapper;
