import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { routes } from "../utils/routes";
import {
  colorSchemes,
  fontStyleContact,
  fontStyleContactHeadline,
  marginContact,
  fontFamily, fontWeight
} from "../utils/theme";
import EmailIcon from "./EmailIcon";
import PhoneIcon from "./PhoneIcon";
import ArrowRightIcon from "./ArrowRightIcon";

const Comp = styled.div({
  display: "flex",
  justifyContent: "center",
  ".fill": {
    fill: colorSchemes.light.color
  },
  ".stroke": {
    stroke: colorSchemes.light.color
  },
  marginTop: "43px",
  marginBottom: "44px"
});
const InnerWrapper = styled.div({
  flex: "0 1 auto"
});
const Headline = styled.h2(({ resolutionCoarse }) => {
  return {
    marginBlockStart: "12px",
    marginBlockEnd: "19px",
    fontFamily: fontFamily.headline,
    fontWeight: fontWeight.medium,
    ...fontStyleContactHeadline[resolutionCoarse],
  };
});
const ContactWrapper = styled.div(({ resolutionCoarse }) => {
  return {
    ...fontStyleContact[resolutionCoarse],
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    whiteSpace: "pre-wrap",
    u: {
      textDecoration: "underline"
    },
  };
});
const LinkText = styled.div(({ resolutionCoarse }) => ({
  marginLeft: marginContact[resolutionCoarse]
}));

function Contact({ hideFaqLink, headlineKey, subHeadlineKey }) {
  const { t } = useTranslation();
  const { resolutionCoarse } = useViewerClient();
  return (
    <Comp>
      <InnerWrapper>
        <Headline resolutionCoarse={resolutionCoarse}>{t(`contact.${headlineKey || "headline"}`)}</Headline>
        {subHeadlineKey && (
          <ContactWrapper resolutionCoarse={resolutionCoarse}>
            <Trans i18nKey={`contact.${subHeadlineKey}`}>...</Trans>
          </ContactWrapper>
        )}
        {!hideFaqLink && (
          <Link to={`/${routes.faq.path}`}>
            <ContactWrapper resolutionCoarse={resolutionCoarse}>
              <ArrowRightIcon/><LinkText resolutionCoarse={resolutionCoarse}>{t("contact.faq.text")}</LinkText><u>FAQ</u>
            </ContactWrapper>
          </Link>
        )}
        {false && (<a href={`tel:${t("contact.tel.number")}`}>
          <ContactWrapper resolutionCoarse={resolutionCoarse}>
            <PhoneIcon/><LinkText resolutionCoarse={resolutionCoarse}>{t("contact.tel.text")}</LinkText>
          </ContactWrapper>
        </a>)}
        {false && (<a href={`mailto:${t("contact.email.text")}`}>
          <ContactWrapper resolutionCoarse={resolutionCoarse}>
            <EmailIcon />
            <LinkText resolutionCoarse={resolutionCoarse}>{t("contact.email.text")}</LinkText>
          </ContactWrapper>
        </a>)}
        <ContactWrapper resolutionCoarse={resolutionCoarse}>
            Die Service-Hotline des Kulturfonds Energie wurde zum 31. März 2024 eingestellt. Antragstellende haben aber weiterhin die Möglichkeit, Fragen zu eingereichten Anträgen direkt im Kommunikationsfeld der Antragsplattform zu stellen.
          </ContactWrapper>
      </InnerWrapper>
    </Comp>
  );
}

export default Contact;
