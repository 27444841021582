import React, { useState } from "react";
import styled from "styled-components";
import { useStyles } from "../context/styles";
import { useViewerClient } from "../context/viewerClient";
import Column from "./Column";
import Container from "./Container";
import { containerWidthsText, containerWidthsIndent, themeButtonReset, fontSize, fontWeight } from "../utils/theme";

const columnStyles = {
  display: 'none'
};

const columnStylesExpanded = {
  display: 'block'
};

const Comp = styled.div(() => ({
  marginTop: '-70px',
  position: 'relative',
  overflow: 'hidden',
}));

const TabsContainer = styled.div(({ gradient }) => ({
    background: gradient,
    paddingBottom: '17px',
}));

const Tab = styled.button(({ resolutionCoarse, active }) => {
  const inactiveStyles = {
    background: 'rgba(255, 255, 255, 0.6);',
    color: '#000000',
    cursor: 'pointer',
    fontWeight: fontWeight.regular,
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '-18px',
      right: '0',
      height: '18px',
      width: '100%',
      background: '#ffffff',
      cursor: 'auto',
      pointerEvents: 'none',
    },
    ':first-child': {
      paddingLeft: '50vw',
      marginLeft: '-50vw',
      textAlign: resolutionCoarse === 'mobile' ? 'center' : 'left',
      paddingRight: '5px',
    },
    ':last-child': {
      paddingRight: '50vw',
      marginRight: '-50vw',
      paddingLeft: '5px',
    },
  }
  const activeStyles = {
    color: '#ffffff',
    fontWeight: fontWeight.medium,
    cursor: 'auto',
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '-18px',
      right: '50%',
      borderBottom: '18px solid #ffffff',
      borderRight: '18px solid transparent',
      height: '0px',
      width: '50%',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '-18px',
      left: 'calc(50% + -2px)',
      borderBottom: '18px solid #ffffff',
      borderLeft: '18px solid transparent',
      height: '0px',
      width: '50%',
    },
    ':first-child': {
      textAlign: resolutionCoarse === 'mobile' ? 'center' : 'left',
      paddingRight: '5px',
      '&::before': {
        width: '50vw',
      }
    },
    ':last-child': {
      paddingLeft: '5px',
      '&::after': {
        width: '50vw',
      }
    },
  }
  const style = {
    mobile: {
      padding: `13px 0`,
    }
  }
  return {
    ...themeButtonReset,
    margin: 0,
    padding: `20px 0`,
    textAlign: 'center',
    position: 'relative',
    fontSize: fontSize.d50x,
    hyphens: 'manual',
    ...(style[resolutionCoarse] || null),
    ...(active ? activeStyles : inactiveStyles)
  }
});

const TabContent = styled.div({
  maxWidth: '803px',
});

function ContentSwitcher({content}) {
  const { resolutionCoarse } = useViewerClient();
  const { gradient } = useStyles();
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <Comp resolutionCoarse={resolutionCoarse}>
      <TabsContainer gradient={gradient}>
        <Container contentSpacings={containerWidthsIndent}
          contentStyles={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)'
          }}
        >
          {content.tabs.map(({title}, index) => (
            <Tab
              dangerouslySetInnerHTML={{__html: title}}
              active={activeIndex === index}
              onClick={() => setActiveIndex(index)}
              resolutionCoarse={resolutionCoarse}
              tabIndex={activeIndex === index ? -1 : 0}>
            </Tab>
          ))}
        </Container>
      </TabsContainer>

      <Container
        contentSpacings={containerWidthsText}
        contentStyles={{
          paddingTop: '32px'
        }}
      >
        <TabContent>
          {content.tabs.map(({paragraphs}, index) => {
            return paragraphs && paragraphs.length > 0 &&
              (
                <Column
                  isExpanded={true}
                  content={{ paragraphs }}
                  columnStyles={index === activeIndex ? columnStylesExpanded : columnStyles}
                />
              )})}
        </TabContent>
      </Container>
    </Comp>
  );
}

export default ContentSwitcher;
