import React from "react";

function FHHIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="63" viewBox="0 0 155.53 100.78">
      <g>
        <path className="fill" d="M114.26,46.04l0.26-0.07v2.65c-22.3,6.19-38.41,4.49-60.68,0.06c-19.23-3.82-33.86-6.62-53.11-0.03v-2.7
		c19.3-6.43,34.36-3.6,53.61,0.23C76.1,50.5,92.09,52.3,114.26,46.04L114.26,46.04z"/>
        <path className="fill" d="M81.89,46.81l-0.09-8.38c0-2.52-2.04-4.56-4.56-4.56c-2.52,0-4.56,2.04-4.56,4.56l-0.08,7.6
		c-4.91-0.59-9.67-1.4-14.36-2.26c2.01-5.56,3.03-10,3.45-16.31h31.1c0.44,6.58,1.92,12.78,4.16,18.95
		C91.73,46.94,86.73,47.03,81.89,46.81L81.89,46.81z"/>
        <polygon className="fill" points="68.02,8.94 66.37,8.94 65.54,10.37 64.72,8.94 63.07,8.94 63.89,7.51 63.07,6.08 64.72,6.08
		65.54,4.65 66.37,6.08 68.02,6.08 67.2,7.51 68.02,8.94 	"/>
        <polygon className="fill" points="91.44,8.94 89.79,8.94 88.96,10.37 88.13,8.94 86.48,8.94 87.31,7.51 86.48,6.08 88.13,6.08
		88.96,4.65 89.79,6.08 91.44,6.08 90.61,7.51 91.44,8.94 	"/>
        <path className="fill" d="M73,9.55V9.4c0-2,1.4-3.68,3.27-4.11V3.46h-1.54V1.75l1.54,0V0h1.95v1.75l1.54,0v1.71h-1.54v1.82
		c1.99,0.44,3.16,2.22,3.27,4.27h1.65v2.18h-1.85v11.69h3.99V11.73h7.36v11.69h2.32v2.25H59.54v-2.25h2.32l0-11.69h7.36v11.69h3.99
		V11.73h-1.85V9.55H73L73,9.55z"/>
      </g>
      <g>
        <path className="fill" d="M0.25,65.27h3.4v12.43h13.93V65.27h3.4v27.59h-3.4V79.81H3.65v13.04h-3.4V65.27z"/>
        <path className="fill" d="M42.05,80.01v12.85h-2.97v-2.89c-1.31,1.93-3.59,3.28-7.06,3.28c-3.4,0-5.36-1.97-5.36-4.75
		c0-4.9,5.52-6.02,12.12-6.56H39v-1.89c0-2.39-1.27-3.78-4.17-3.78c-4.32,0-7.37,2.39-7.37,2.39l0.35-2.74c0,0,2.78-2.2,7.29-2.2
		C40.74,73.72,42.05,76.8,42.05,80.01z M39,85.29v-1.54l-0.15,0.04c-5.09,0.46-8.84,1.24-8.84,4.4c0,1.78,1,2.7,2.89,2.7
		C36.84,90.89,39,88.38,39,85.29z"/>
        <path className="fill" d="M74.47,79.66v13.2h-3.05V80.09c0-2.28-0.85-3.82-3.16-3.82c-2.97,0-5.36,2.35-5.36,6.13v10.46h-3.05V80.09
		c0-2.28-0.81-3.82-3.16-3.82c-2.97,0-5.33,2.35-5.33,6.13v10.46h-3.09V74.1h2.89v3.24c1.31-2.2,3.55-3.63,6.37-3.63
		c2.93,0,4.55,1.39,5.13,3.78c1.27-2.32,3.55-3.78,6.44-3.78C72.81,73.72,74.47,75.96,74.47,79.66z"/>
        <path className="fill" d="M97.43,83.48c0,6.64-3.67,9.72-7.8,9.72c-2.89,0-4.86-1.31-6.06-3.24v2.89h-2.89V65.27h3.01v11.92
		c1.27-2.08,3.4-3.47,6.33-3.47C94.42,73.72,97.43,76.92,97.43,83.48z M94.15,83.52c0-4.98-1.62-7.33-4.94-7.33
		c-3.05,0-5.52,2.43-5.52,7.33c0,4.9,2.35,7.25,5.4,7.25C92.41,90.77,94.15,88.61,94.15,83.52z"/>
        <path className="fill" d="M118.39,74.1v18.75h-2.89v-3.28c-1.39,2.24-3.82,3.67-6.87,3.67c-3.94,0-5.71-2.24-5.71-5.94V74.1H106v12.77
		c0,2.28,0.89,3.82,3.47,3.82c3.13,0,5.83-2.35,5.83-6.14V74.1H118.39z"/>
        <path className="fill" d="M135.72,77.19c0,0-0.73-0.23-1.97-0.23c-3.2,0-5.87,2.82-5.87,7.49v8.41h-3.09V74.1h2.97v4.63
		c1.35-3.01,3.78-5.02,6.48-5.02c0.62,0,1.16,0.12,1.16,0.12L135.72,77.19z"/>
        <path className="fill" d="M155.51,74.1v19.45c0,3.55-2.01,7.1-8.68,7.1c-4.36,0-6.79-2.32-6.79-2.32l-0.39-2.86c0,0,2.82,2.51,7.33,2.51
		c3.59,0,5.52-1.54,5.52-4.4v-4.48c-1.31,2.01-3.4,3.36-6.33,3.36c-4.4,0-7.41-3.05-7.41-9.38c0-6.41,3.67-9.38,7.79-9.38
		c2.89,0,4.82,1.24,6.02,3.13V74.1H155.51z M152.5,83.06c0-4.71-2.35-6.91-5.4-6.91c-3.32,0-5.05,2.08-5.05,6.91
		c0,4.71,1.62,6.98,4.94,6.98C150.03,90.04,152.5,87.76,152.5,83.06z"/>
      </g>
    </svg>
  );
}
export default FHHIcon;
