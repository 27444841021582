import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useViewerClient } from "../context/viewerClient";
import { useVersionedContent } from "../context/versionedContent";
import { colorSchemes, fontSize, fontWeight, themeButtonReset } from "../utils/theme";
import ContentFilterCompare from "./ContentFilterCompare";
import ContentFilterPrint from "./ContentFilterPrint";
import ContentFilterSearch from "./ContentFilterSearch";

const allModes = [["show", "compare"], ["search"], ["print"]];

const Comp = styled.div({});

const TabsGroup = styled.div({
  display: "flex",
  flexWrap: "wrap",
  marginBottom: "76px"
});

const TabNavItem = styled.div(({ isFirst, isMobile, isLast }) => {
  const padding = isMobile ? "8px" : "35px";
  return {
    flex: `${isMobile ? 1 : 0} 1 auto`,
    marginTop: "10px",
    paddingLeft: isFirst ? null : padding,
    paddingRight: isMobile && isLast ? null : padding,
    textAlign: isMobile ? "center" : "left",
    borderLeft: isFirst ? null : `1px solid ${colorSchemes.light.color}`,
    height: "36px"
  }
});

const TabNavButton = styled.button(({ isActive, isMobile }) => {
  const border = { borderBottom: isActive ? `4px solid ${colorSchemes.light.color}` : `4px solid transparent` };
  return {
    ...themeButtonReset,
    color: colorSchemes.light.color,
    height: isMobile ? "40px" : "43px",
    fontSize: fontSize.d30x,
    fontWeight: fontWeight.medium,
    ...border,
    ":active": border, 
    ":focus": border
  }
});

function ContentFilter({ id }) {
  const { isGTETabletP } = useViewerClient();
  const { modes, setMode, versionLists } = useVersionedContent();
  const { t } = useTranslation();
  const history = useHistory();
  const { location } = history;
  const { hash } = location;

  const avaliableModes = versionLists[id] && versionLists[id].length > 1 ? allModes : [["search"], ["print"]];

  const mode = (modes && modes.faq) || avaliableModes[0][0] || avaliableModes[0];

  useEffect(() => {
    if (mode && !modes.faq && mode !== "show" && versionLists[id]) {
      // mode was not set in context and is not fallback mode
      setMode(id, mode);
    }
  },[id, mode, modes.faq, setMode, versionLists]);

  const handleOnSwitch = (newMode) => {
    setMode(id, newMode);
    if (hash) {
      location.hash = null;
      history.push({ ...location });
    }
  };

  return (
    <Comp>
      <TabsGroup role="tablist">
        {avaliableModes.map((ms, index) => (
          <TabNavItem isMobile={!isGTETabletP} key={ms[0]} isFirst={index === 0} isLast={index === avaliableModes.length - 1}>
            <TabNavButton role="tab" aria-selected={ms.includes(mode)} isActive={ms.includes(mode)} isMobile={!isGTETabletP} onClick={() => !ms.includes(mode) && handleOnSwitch(ms[0])}>
              {t(`contentFilter.${ms[0]}.headline`)}
            </TabNavButton>
          </TabNavItem>
        ))}
      </TabsGroup>
      {allModes[0].includes(mode) && (
        <ContentFilterCompare id={id} />
      )}
      {mode === "search" && (
        <ContentFilterSearch id={id} />
      )}
      {mode === "print" && (
        <ContentFilterPrint id={id} />
      )}
    </Comp>
  );
}

export default ContentFilter;
