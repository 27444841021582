import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { fontStyleH1, vSpacing, fontFamily } from "../utils/theme";

const Comp= styled.h1(({ resolutionCoarse }) => {
  return {
    marginBlockEnd: `${vSpacing.headline[resolutionCoarse]}px`,
    marginBlockStart: `${vSpacing.title[resolutionCoarse]}px`,
    fontFamily: fontFamily.headline,
    ...fontStyleH1[resolutionCoarse]
  }
});

function H1({ children }) {
  const { resolutionCoarse } = useViewerClient();
  return (
    <Comp resolutionCoarse={resolutionCoarse} >{ children }</Comp>
  );
}

export default H1;
