import React from "react";
import Page from "../components/Page";
import Block from "../components/Block";
import content from "../locales/de/content";

const Legal = () => {
  return (
    <Page>
      <Block content={content.legal} prefix="legal"/>
      <Block content={content.contact} prefix="contact" type="cta" theme="grey"/>
    </Page>
  );
};

export default Legal;