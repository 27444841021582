import React, { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colorSchemes, contentSpacing, fontFamily, fontWeight } from "../utils/theme";

const Comp = styled.a(({ resolution, showButton }) => {
  const buttonStyle = showButton
    ? { opacity: 1, transition: "opacity 0.25s" }
    : { opacity: 0, transition: "opacity 2s" };
  return {
    ...colorSchemes.dark,
    ...buttonStyle,
    fontFamily: fontFamily.normal,
    fontWeight: fontWeight.semiBold,
    fontSize: "21px",
    display: "flex",
    alignItems: "center",
    position: "fixed",
    bottom: "6vh",
    zIndex: 5,
    right: contentSpacing[resolution],
    boxShadow: `0px 3px 16px #00000040`,
    textDecoration: "none",
    padding: "9px 20px",
    lineHeight: "30px",
    borderRadius: "24px",
    ":focus": {
      ...colorSchemes.dark,
      textDecoration: "none"
    },
    ":hover": {
      ...colorSchemes.dark,
      textDecoration: "none",
    },
    ":active": {
      ...colorSchemes.dark,
      textDecoration: "none"
    }
  };
});

const ApplyButton = ({ children, ...props }) => {
  const { t } = useTranslation();
  const [showButton, setShowButton] = useState(false);
  const compRef = useRef(null);

  useLayoutEffect(() => {
    if (window) {
      let timeoutId;
      const handleScroll = () => {
        setShowButton(true);
        timeoutId = setTimeout(() => {
          if (compRef.current) {
            setShowButton(false);
          } else {
          }
        }, 1750);
      };
      window.addEventListener("touchstart", handleScroll);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("touchstart", handleScroll);
        window.removeEventListener("scroll", handleScroll);
        window.clearTimeout(timeoutId);
      };
    }
  });

  return (
    <Comp showButton={showButton} {...props} ref={compRef} href={process.env.REACT_APP_APPLY_LINK} target="_blank" rel="noreferrer">
        {t("link.apply.textMobile")}
    </Comp>
  );
};

export default ApplyButton;
