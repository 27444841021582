import React from "react";
import styled from "styled-components";
import {colorSchemes, fontWeight} from "../utils/theme";


const Comp = styled.li(({ color }) => ({
  listStyle: "none",
  a: {
    color,
    fontWeight: fontWeight.regular,
    textUnderlinePosition: "under"
  },
  "a:focus": {
    textDecoration: "underline"
  },
  "a:hover": {
    textDecoration: "underline"
  },
  "a:active": {
    textDecoration: "underline"
  }
}));

const NavLinkItem = ({ children, colorScheme = colorSchemes.light }) => {
  return (
    <Comp color={colorScheme.color}>
      {children}
    </Comp>
  );
};

export default NavLinkItem;