import React from "react";
import styled from "styled-components";
import { colorSchemes, fontSize, fontWeight, themeButtonReset } from "../utils/theme";

const Comp = styled.div({
  flex: "0 0 auto",
  padding: "2px"
});

const Button = styled.button({
  ...themeButtonReset,
  fontSize: fontSize.d40x,
  fontWeight: fontWeight.medium,
  height: "61px",
  ...colorSchemes.dark,
  borderRadius: "2px",
  width: "196px"
});

function InputGroupButton({ onClick, children }) {
  return (
    <Comp>
      <Button onClick={onClick}>{children}</Button>
    </Comp>
  );
}

export default InputGroupButton;
