import React from "react";
import styled from "styled-components";
import CrossIcon from "./CrossIcon";
import { colors, fontSize, themeButtonReset } from "../utils/theme";

const Comp= styled.button({
  ...themeButtonReset,
  color: colors.WHITE,
  background: colors.BLACK,
  height: "36px",
  borderRadius: "18px",
  fontSize: fontSize.d20x,
  paddingLeft: "18px",
  paddingRight: "18px",
  display: "flex",
  alignItems: "center",
  svg: {
    width: "20px",
    height: "20px",
    marginLeft: "1em"
  }
});

function ChipButton({ children, onClick }) {
  return (
    <Comp onClick={onClick}>{ children }<CrossIcon /></Comp>
  );
}

export default ChipButton;
