import React from "react";
import { colorSchemes } from "../utils/theme";

function DocumentIcon({ size = 48, color = colorSchemes.light.color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
      <g transform="translate(-772.987 -175.242)">
        <g transform="translate(0 -0.592)">
          <rect width="48" height="48" transform="translate(772.987 175.833)" fill="none" />
          <path
            d="M804.987,177.833h-24a4.012,4.012,0,0,0-4,4v28h4v-28h24Zm-2,8,12,12v20a4.012,4.012,0,0,1-4,4h-22.02a4,4,0,0,1-3.98-4l.02-28a4,4,0,0,1,3.98-4Zm-2,14h11l-11-11Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
export default DocumentIcon;
