export const breakpoints = {
  mobileL: 450,
  tabletP: 768,
  tabletL: 1024,
  desktop: 1280,
  wide: 1920
};

export const colors = {
  BLACK: "#000000",
  BLUE_LIGHT: "#EDF5FF",
  BLUE_MEDIUM: "#A1D9FD",
  ENERGY_MEDIUM_GREY: "#C6C6C6",
  GREY_DARK: "#1F1F1F",
  GREY_MEDIUM_DARK: "#6D6D6D",
  GREY_MEDIUM: "#B5B5B5",
  GREY_MEDIUM_LIGHT: "#E8E8E8",
  GREY_LIGHT: "#F5F5F5",
  GREY_STRONG: "#EDEDED",
  PIGEON: "#5E677F",
  RED: "#FF4D4D",
  TEAL: "#F6F8F9",
  WHITE: "#FFFFFF",
  MARINE: "#122046",
  YELLOW_LIGHT: "#FEFFDD",
  YELLOW_MEDIUM: "#FFEE6F"
};


export const backgrounds = {
  black: colors.BLACK,
  dark: colors.GREY_DARK,
  marine: colors.MARINE,
  medium: colors.GREY_MEDIUM_DARK,
  transparent: "transparent",
  grey: colors.GREY_LIGHT,
  strong_grey: colors.GREY_STRONG,
  yellow: colors.YELLOW_LIGHT,
  blue: colors.BLUE_LIGHT,
  teal: colors.TEAL,
  white: colors.WHITE
};

export const gradientList = [
  {
    gradient : ["#F06F6A 0%", "#EA7472 31%", "#CC9CD8 100%"], // rosa-orange bis lila
    gradientProcess : ["#EA7F7F 0%", "#E28091 31%", "#D196C8 100%"]
  },
  {
    gradient : ["#81B2F2 0%", "#8CB5DF 39%", "#FCFEC6 100%"], // blau bis grün
    gradientProcess : ["#90B8E3 0%", "#A2C3DA 39%", "#E8F1CA 100%"]
  },
  {
    gradient : ["#FF6300 0%", "#CE89A8 81%", "#CC8AAD 100%"], // orange bis lila
    gradientProcess : ["#EB7548 0%", "#E07B6A 81%", "#CD89AA 100%"]
  }
]


export const transitionSpeed = "250m";

export const animationDuration = "24s"
export const animationKeyframes = [0, 0.45, 0.5, 0.95, 1];

export const fontFamily = {
  stage: "RobotoSlabVariable, sans-serif",
  headline: "RobotoSlabVariable, sans-serif",
  normal: "SourceSansPro, sans-serif",
  mono: "SourceSansPro, sans-serif",
  printSerif: "Times New Roman, Times, Georgia, serif",
  printSans: "Arial, Verdana, Helvetica, sans-serif"
};

export const fontWeight = {
  light: 300,
  regular: 400,
  text: 400,
  medium: 500,
  semiBold: 600,
  bold: 700
};

export const fontSize = {
  d10: "0.624rem", // 16.23px /
  d20: "0.702rem", // 18.26px / 14.05px
  d30: "0.790rem", // 20.54px / 15.80px
  d40: "0.889rem", // 23.11px / 17.78px
  d50: "1rem", // 26px / 20px
  d60: "1.125rem", // 29.25px / 22.5px
  m60: "1.266rem", // - / 25.31px
  m70: "1.424rem", // - / 28.48px

  // Extra font size multiplied by 1.07 for SourceSansPro font
  d10x: "0.668rem",
  d20x: "0.751rem",
  d30x: "0.845rem",
  d40x: "0.951rem",
  d50x: "1.07rem",
  d60x: "1.204rem",
  m60x: "1.355rem",
  m70x: "1.524rem"
};

export const tabSize = {
  l0: 0.9,
  l1: 1.7
};

export const numIndent = {
  l0: 2.5,
  l1: 5.7
};

export const tickerHeight = 42;
export const tickerHeightMobile = 36;

export const hSpacing = {
  page: {
    mobile: {
      left: 16,
      right: 30
    },
    tablet: {
      left: 16,
      right: 30
    },
    desktop: {
      left: 32,
      right: 48
    }
  },
  textLogo: {
    mobile: {
      left: 108,
      bottom: 12
    },
    tablet: {
      left: 108,
      bottom: 12
    },
    desktop: {
      left: 142,
      bottom: 20
    }
  },
  content: {
    mobile: {
      left: 32,
      right: 62
    },
    tablet: {
      left: 32,
      right: 62
    },
    desktop: {
      left: 78,
      right: 62
    }
  },
  indent: {
    mobile: {
      left: 78,
      right: 62
    },
    tablet: {
      left: 78,
      right: 62
    },
    desktop: {
      left: 64,
      right: 198
    }
  },
  colGap: {
    mobile: 32,
    tablet: 32,
    desktop: 32
  },
  maxTextWidth: {
    mobile: 1060,
    tablet: 1060,
    desktop: 1060
  }
};

export const vSpacing = {
  header: {
    mobile: {
      top: 16,
      height: 28,
      bottom: 16
    },
    tablet: {
      top: 16,
      height: 28,
      bottom: 16
    },
    desktop: {
      top: 24,
      height: 42,
      bottom: 21
    }
  },
  block: {
    mobile: {
      top: 72,
      bottom: 40
    },
    tablet: {
      top: 72,
      bottom: 36
    },
    desktop: {
      top: 72,
      bottom: 36
    }
  },
  container: {
    mobile: {
      top: 12,
      bottom: 60
    },
    tablet: {
      top: 12,
      bottom: 60
    },
    desktop: {
      top: 12,
      bottom: 60
    }
  },
  title: {
    mobile: 18,
    tablet: 14,
    desktop: 14
  },
  headline: {
    mobile: 18,
    tablet: 12,
    desktop: 12
  },
  text: {
    mobile: 18,
    tablet: 24,
    desktop: 24
  }
};

export const maxContentWidth = "1280px";
export const maxTextWidth = `${hSpacing.maxTextWidth.desktop}px`;

export const contentSpacing = {
  mobileP: "16px",
  mobileL: "30px",
  tabletP: "72px",
  tabletL: "96px",
  desktop: "50px",
  wide: "50px"
};

export const boxSpacing = {
  mobileP: "15px",
  mobileL: "15px",
  tabletP: "72px",
  tabletL: "100px"
};

export const headerGraphicHeight = 43.39;
export const burgerIconHeight = 22;
export const headerGraphicHeightMobile = 40;

export const mobileGraphicHeight = "68px";

export const indentAccordionLeftMobile = 16;
export const indentAccordionLeftDesktop = 16;
export const indentAccordionRight = 58;

export const fontStyleStage = {
  mobile: {
    fontFamily: fontFamily.stage,
    fontSize: "40px",
    lineHeight: "50px",
    fontWeight: fontWeight.medium,
  },
  tablet: {
    fontFamily: fontFamily.stage,
    fontSize: "64px",
    lineHeight: "77px",
    fontWeight: fontWeight.medium,
  },
  desktop: {
    fontFamily: fontFamily.stage,
    fontSize: "80px",
    fontWeight: fontWeight.medium,
  }
};

export const fontStyleH1 = {
  mobile: {
    fontSize: "23px",
    lineHeight: "36px",
  },
  tablet: {
    fontSize: "23px",
    lineHeight: "36px"
  },
  desktop: {
    fontSize: "26px",
    lineHeight: "36px",
  }
};

export const fontStyleH2 = {
  mobile: {
    fontSize: fontSize.m60,
    lineHeight: "29px"
  },
  tablet: {
    fontSize: fontSize.m60,
    lineHeight: "46px"
  },
  desktop: {
    fontSize: fontSize.d60,
    lineHeight: "46px",
  }
};

export const fontStyleH2Large = {
  mobile: {
    fontSize: fontSize.m60x,
    lineHeight: "29px"
  },
  tablet: {
    fontSize: fontSize.m60x,
    lineHeight: "46px"
  },
  desktop: {
    fontSize: fontSize.d60x,
    lineHeight: "46px",
  }
};

export const fontStyleH3 = {
  mobile: {
    fontSize: fontSize.d50x,
    lineHeight: "30px"
  },
  tablet: {
    fontSize: fontSize.d50x,
    lineHeight: "33px"
  },
  desktop: {
    fontSize: fontSize.d40x,
    lineHeight: "33px"
  }
};

export const fontStyleH3Large = {
  mobile: {
    fontSize: fontSize.d50,
    lineHeight: "30px"
  },
  tablet: {
    fontSize: fontSize.d50,
    lineHeight: "30px"
  },
  desktop: {
    fontSize: fontSize.d40,
    lineHeight: "33px"
  }
};

export const fontStyleH4 = {
  mobile: {
    fontSize: fontSize.d40x,
    lineHeight: "30px"
  },
  tablet: {
    fontSize: fontSize.d50x,
    lineHeight: "30px"
  },
  desktop: {
    fontSize: fontSize.d30x,
    lineHeight: "30px"
  }
};

export const fontStyleH4Print = {
  mobile: {
    fontSize: "18px",
    lineHeight: "28px"
  },
  tablet: {
    fontSize: "20px",
    lineHeight: "30px"
  },
  desktop: {
    fontSize: "20px",
    lineHeight: "30px"
  }
};

export const fontStyleAbstract = {
  mobile: {
    fontSize: fontSize.d60,
    fontWeight: fontWeight.regular,
    lineHeight: "38px"
  },
  tablet: {
    fontSize: fontSize.m60,
    fontWeight: fontWeight.regular,
    lineHeight: "38px"
  },
  desktop: {
    fontSize: fontSize.d50,
    fontWeight: fontWeight.regular,
    lineHeight: "38px"
  }
};

export const fontStyleText = {
  mobile: {
    fontSize: fontSize.d40x,
    fontWeight: fontWeight.text,
    lineHeight: "30px"
  },
  tablet: {
    fontSize: fontSize.d50x,
    fontWeight: fontWeight.text,
    lineHeight: "30px"
  },
  desktop: {
    fontSize: fontSize.d30x,
    fontWeight: fontWeight.text,
    lineHeight: "30px"
  }
};

export const fontStyleContactHeadline = {
  mobile: {
    fontSize: fontSize.d30x,
  },
  tablet: {
    fontSize: fontSize.d40x,
  },
  desktop: {
    fontSize: fontSize.d40x,
    lineHeight: "48px"
  }
};

export const fontStyleContact = {
  mobile: {
    fontSize: fontSize.d30x,
    fontWeight: fontWeight.text
  },
  tablet: {
    fontSize: fontSize.d40x,
    fontWeight: fontWeight.text
  },
  desktop: {
    fontSize: fontSize.d40x,
    fontWeight: fontWeight.text
  }
};
// depends on fontsize and font of fontStyleContact, must equal 3 spaces
export const marginContact = {
  mobile: "0.5rem",
  tablet: "0.7rem",
  desktop: "0.6rem"
};

export const linkStyles = {
  a: {
    display: "inline-block",
    textDecoration: "underline",
    svg: {
      position: "relative",
      marginRight: "0.25em",
      height: "0.75em",
      width: "0.75em"
    }
  },
  ".faq-link": {
    whiteSpace: "normal",
    svg: {
      height: "0.6em",
      width: "0.6em"
    }
  },
  ".file-link svg": {
    height: "1em",
    width: "auto",
    marginLeft: "0.5em",
    marginBottom: "-0.1em"
  }
};

export const diffStyles = {
  "ins": {
    background: colors.YELLOW_MEDIUM,
    textDecoration: "inherit"
  },
  "del": {
    textDecoration: "line-through"
  },
  ".match": {
    background: colors.BLUE_MEDIUM,
  }
};

export const fontStyleAccordion = {
  0: {
    mobile: {
      fontSize: fontSize.d60x,
      lineHeight: "33px",
      fontWeight: fontWeight.medium,
      marginBlockStart: "15px",
      marginBlockEnd: "15px"
    },
    tablet: {
      fontSize: fontSize.m60x,
      lineHeight: "33px",
      fontWeight: fontWeight.medium,
      marginBlockStart: "15px",
      marginBlockEnd: "15px"
    },
    desktop: {
      fontSize: fontSize.d50x,
      lineHeight: "36px",
      fontWeight: fontWeight.medium,
      marginBlockStart: "15px",
      marginBlockEnd: "15px"
    }
  },
  1: {
    mobile: {
      fontSize: fontSize.d50x,
      lineHeight: "30px",
      fontWeight: fontWeight.medium,
      marginBlockStart: "18px",
      marginBlockEnd: "18px"
    },
    tablet: {
      fontSize: fontSize.d50x,
      lineHeight: "30px",
      fontWeight: fontWeight.medium,
      marginBlockStart: "18px",
      marginBlockEnd: "18px"
    },
    desktop: {
      fontSize: fontSize.d40x,
      lineHeight: "33px",
      fontWeight: fontWeight.medium,
      marginBlockStart: "18px",
      marginBlockEnd: "18px"
    }
  }
};

export const controlStyleAccordion = {
  0: {
    mobile: {
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: fontWeight.regular,
      marginBlockStart: "12px",
      marginBlockEnd: "12px"
    },
    tablet: {
      fontSize: "42px",
      lineHeight: "36px",
      fontWeight: fontWeight.regular,
      marginBlockStart: "15px",
      marginBlockEnd: "15px"
    },
    desktop: {
      fontSize: "42px",
      lineHeight: "36px",
      fontWeight: fontWeight.regular,
      marginBlockStart: "15px",
      marginBlockEnd: "15px"
    }
  },
  1: {
    mobile: {
      fontSize: "20px",
      lineHeight: "28px",
      marginBlockStart: "12px",
      marginBlockEnd: "12px"
    },
    tablet: {
      fontSize: "24px",
      lineHeight: "30px",
      marginBlockStart: "19px",
      marginBlockEnd: "19px"
    },
    desktop: {
      fontSize: "24px",
      lineHeight: "30px",
      marginBlockStart: "19px",
      marginBlockEnd: "19px"
    }
  }
};

export const containerStylesAccordion = {
  0: {
    mobile: {
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    tablet: {
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    desktop: {
      paddingTop: "20px",
      paddingBottom: "20px"
    }
  },
  1: {
    mobile: {
      paddingBottom: "20px"
    },
    tablet: {
      paddingBottom: "20px",
    },
    desktop: {
      paddingBottom: "20px"
    }
  }
};

export const textStylesFilterNoControl = {
  marginBottom: "32px",
  marginTop: "64px"
};

export const styleTabNavItem = {
  mobile: {
    textAlign: "left",
    height: "202px",
    padding: `${contentSpacing.mobileP} 35px`
  },
  tablet: {
    textAlign: "center",
    padding: "30px"
  },
  desktop: {
    textAlign: "center",
    padding: "30px 50px"
  }
};

export const fontStyleTabHeadline = {
  mobile: {
    fontSize: "18px",
    lineHeight: "28px"
  },
  tablet: {
    fontSize: "24px",
    lineHeight: "30px"
  },
  desktop: {
    fontSize: "30px",
    lineHeight: "36px"
  }
};

export const fontStyleTabSubline = {
  mobile: {
    fontSize: "18px",
    lineHeight: "28px"
  },
  tablet: {
    fontSize: "20px",
    lineHeight: "30px"
  },
  desktop: {
    fontSize: "24px",
    lineHeight: "34px"
  }
};

export const fontStyleTextBoxHeadline = {
  mobile: {
    fontSize: "24px",
    lineHeight: "28px",
    paddingTop: "0.5em",
    paddingBottom: "0.5em"
  },
  tablet: {
    fontSize: "24px",
    lineHeight: "36px",
    paddingTop: "0.5em",
    paddingBottom: "0.5em"
  },
  desktop: {
    fontSize: "30px",
    lineHeight: "36px",
    paddingTop: "0.5em",
    paddingBottom: "0.5em"
  }
};

export const fontStyleProcessHeadline = {
  mobile: {
    fontFamily: fontFamily.mono,
    fontSize: fontSize.d30,
    lineHeight: "32px"
  },
  tablet: {
    fontFamily: fontFamily.mono,
    fontSize: fontSize.d20x,
    lineHeight: "28px"
  },
  desktop: {
    fontFamily: fontFamily.mono,
    fontSize: fontSize.d20x,
    lineHeight: "28px"
  }
};
export const fontStyleProcessLabel = {
  mobile: {
    fontSize: fontSize.d30,
    lineHeight: "28px",
    height: mobileGraphicHeight,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  tablet: {
    fontFamily: fontFamily.mono,
    fontSize: fontSize.d10x,
    lineHeight: "24px"
  },
  desktop: {
    fontFamily: fontFamily.mono,
    fontSize: fontSize.d10x,
    lineHeight: "24px"
  }
};
export const themeButtonReset = {
  padding: 0,
  border: "none",
  cursor: "pointer",
  backgroundColor: "transparent",
  fontFamily: "inherit",
  ":focus": {
    border: "none"
  },
  ":active": {
    border: "none",
    outline: "none"
  }
};

export const themeTicker = {
  mobile: {
    fontSize: fontSize.d20x,
    fontWeight: fontWeight.medium,
    lineHeight: `${tickerHeightMobile}px`
  },
  tablet: {
    fontSize: fontSize.d20x,
    fontWeight: fontWeight.medium,
    lineHeight: `${tickerHeight}px`
  },
  desktop: {
    fontSize: fontSize.d20x,
    fontWeight: fontWeight.medium,
    lineHeight: `${tickerHeight}px`
  }
}

export const colorSchemes = {
  dark: {
    color: colors.WHITE,
    background: colors.BLACK
  },
  light: {
    color: colors.BLACK,
    background: colors.WHITE
  }
}
export const themeTopBarButton = {
  fontFamily: fontFamily.normal,
  fontSize: "21px",
  ...colorSchemes.dark
};

export const themeFontStage = {
  fontFamily: fontFamily.stage,
  textTransform: "uppercase",
  fontWeight: "bold"
};

export const themeHeadline = {
  underline: {
    borderBottom: `2px solid ${colorSchemes.light.color}`
  }
};

export const containerWidthsDefault = {
  mobileP: {},
  mobileL: {},
  tabletP: {},
  tabletL: {},
  desktop: {},
  wide: {
    flexBase: "1920px"
  }
};

export const containerWidthsText = {
  mobileP: {
    paddingLeft: contentSpacing.mobileP,
    paddingRight: contentSpacing.mobileP,
    // paddingTop: `${vSpacing.container.mobile.top}px`,
    paddingBottom: `${vSpacing.container.mobile.bottom}px`
  },
  mobileL: {
    paddingLeft: contentSpacing.mobileL,
    paddingRight: contentSpacing.mobileL,
    // paddingTop: `${vSpacing.container.mobile.top}px`,
    paddingBottom: `${vSpacing.container.mobile.bottom}px`
  },
  tabletP: {
    paddingLeft: contentSpacing.tabletP,
    paddingRight: contentSpacing.tabletP,
    // paddingTop: `${vSpacing.container.tablet.top}px`,
    paddingBottom: `${vSpacing.container.tablet.bottom}px`
  },
  tabletL: {
    paddingLeft: contentSpacing.tabletL,
    paddingRight: contentSpacing.tabletL,
    // paddingTop: `${vSpacing.container.tablet.top}px`,
    paddingBottom: `${vSpacing.container.tablet.bottom}px`
  },
  desktop: {
    flexBase: maxTextWidth,
    // paddingTop: `${vSpacing.container.desktop.top}px`,
    paddingBottom: `${vSpacing.container.desktop.bottom}px`
  },
  wide: {
    flexBase: maxContentWidth,
    // paddingTop: `${vSpacing.container.desktop.top}px`,
    paddingBottom: `${vSpacing.container.desktop.bottom}px`
  }
};

export const containerWidthsTabs = {
  mobileP: {
    paddingLeft: boxSpacing.mobileP,
    paddingRight: boxSpacing.mobileP,
    paddingBottom: "40px"
  },
  mobileL: {
    paddingLeft: boxSpacing.mobileL,
    paddingRight: boxSpacing.mobileL,
    paddingBottom: "40px"
  },
  tabletP: {
    paddingLeft: boxSpacing.tabletP,
    paddingRight: boxSpacing.tabletP,
    paddingBottom: "40px"
  },
  tabletL: {
    paddingLeft: boxSpacing.tabletL,
    paddingRight: boxSpacing.tabletL,
    paddingBottom: "40px"
  },
  desktop: {
    flexBase: maxTextWidth,
    paddingBottom: "40px"
  },
  wide: {
    flexBase: maxContentWidth,
    paddingBottom: "40px"
  }
};

export const containerWidthsIndent = {
  mobileP: {
    paddingLeft: contentSpacing.mobileP,
    paddingRight: contentSpacing.mobileP
  },
  mobileL: {
    paddingLeft: contentSpacing.mobileL,
    paddingRight: contentSpacing.mobileL
  },
  tabletP: {
    paddingLeft: contentSpacing.tabletP,
    paddingRight: contentSpacing.tabletP
  },
  tabletL: {
    paddingLeft: contentSpacing.tabletL,
    paddingRight: contentSpacing.tabletL
  },
  desktop: {
    flexBase: maxTextWidth
  },
  wide: {
    flexBase: maxContentWidth
  }
};

export const containerPaddingsText = {
  mobileP: {
    paddingTop: `${vSpacing.block.mobile.top}px`,
    paddingBottom: `${vSpacing.block.mobile.bottom}px`
  },
  mobileL: {
    paddingTop: `${vSpacing.block.mobile.top}px`,
    paddingBottom: `${vSpacing.block.mobile.bottom}px`
  },
  tabletP: {
    paddingTop: `${vSpacing.block.tablet.top}px`,
    paddingBottom: `${vSpacing.block.tablet.bottom}px`
  },
  tabletL: {
    paddingTop: `${vSpacing.block.tablet.top}px`,
    paddingBottom: `${vSpacing.block.tablet.bottom}px`
  },
  desktop: {
    paddingTop: `${vSpacing.block.desktop.top}px`,
    paddingBottom: `${vSpacing.block.desktop.bottom}px`
  },
  wide: {
    paddingTop: `${vSpacing.block.desktop.top}px`,
    paddingBottom: `${vSpacing.block.desktop.bottom}px`
  }
};

export const containerPaddingsTextProcess = {
  mobileP: {
    paddingTop: "66px"
  },
  mobileL: {
    paddingTop: "66px"
  },
  tabletP: {
    paddingTop: "66px"
  },
  tabletL: {
    paddingTop: "66px"
  },
  desktop: {
    paddingTop: "66px"
  },
  wide: {
    paddingTop: "66px"
  }
};

export const containerPaddingsTopText = {
  mobileP: {
    paddingTop: "30px"
  },
  mobileL: {
    paddingTop: "30px"
  },
  tabletP: {
    paddingTop: "90px",
    paddingBottom: "52px"
  },
  tabletL: {
    paddingTop: "90px",
    paddingBottom: "52px"
  },
  desktop: {
    paddingTop: "90px",
    paddingBottom: "52px"
  },
  wide: {
    paddingTop: "90px",
    paddingBottom: "52px"
  }
};

export const containerPaddingsBox = {
  mobileP: {
    paddingTop: "24px",
    paddingBottom: "24px"
  },
  mobileL: {
    paddingTop: "24px",
    paddingBottom: "24px"
  },
  tabletP: {
    paddingTop: "16px",
    paddingBottom: "16px"
  },
  tabletL: {
    paddingTop: "16px",
    paddingBottom: "16px"
  },
  desktop: {
    paddingTop: "16px",
    paddingBottom: "16px"
  },
  wide: {
    paddingTop: "16px",
    paddingBottom: "16px"
  }
};

export const containerPaddingsTabs = {
  mobileP: {
    paddingBottom: "24px"
  },
  mobileL: {
    paddingBottom: "24px"
  },
  tabletP: {
    paddingBottom: "13px",
    minHeight: "400px"
  },
  tabletL: {
    paddingBottom: "13px",
    minHeight: "400px"
  },
  desktop: {
    paddingBottom: "13px",
    minHeight: "400px"
  },
  wide: {
    paddingBottom: "13px",
    minHeight: "400px"
  }
};

export const containerTextBox = {
  mobile: {
    marginLeft: `-${indentAccordionLeftMobile}px`,
    marginRight: `-${indentAccordionRight}px`,
    paddingBottom: "4px",
    paddingLeft: `${indentAccordionLeftMobile + 8}px`,
    paddingRight: `${indentAccordionRight + 8}px`,
    paddingTop: "4px",
    position: "relative"
  },
  tablet: {
    padding: "8px 16px"
  },
  desktop: {
    padding: "10px 32px"
  }
};

export const containerExpandableTextBox = {
  mobileP: {
    marginLeft: `-${contentSpacing.mobileP}`,
    marginRight: `-${contentSpacing.mobileP}`,
    padding: `18px ${contentSpacing.mobileP} 50px ${contentSpacing.mobileP}`
  },
  mobileL: {
    marginLeft: `-${contentSpacing.mobileP}`,
    marginRight: `-${contentSpacing.mobileP}`,
    padding: `18px ${contentSpacing.mobileL} 50px ${contentSpacing.mobileL}`
  },
  tabletP: {
    padding: "28px 22px 60px 65px",
    marginTop: "60px",
    marginBottom: "60px"
  },
  tabletL: {
    padding: "28px 65px 60px 65px",
    marginTop: "60px",
    marginBottom: "60px"
  },
  desktop: {
    padding: "18px 32px 50px 32px",
    marginTop: "60px",
    marginBottom: "60px"
  },
  wide: {
    padding: "18px 32px 50px 32px",
    marginTop: "60px",
    marginBottom: "60px"
  }
};

export const containerTextBoxIndent = {
  /* mobile: {
     "1": {
       marginLeft: "40px"
     },
     "2": {
       marginLeft: "80px"
     }
   },*/
  tablet: {
    "1": {
      marginLeft: "0px"
    },
    "2": {
      marginLeft: "80px"
    }
  },
  desktop: {
    "1": {
      marginLeft: "40px"
    },
    "2": {
      marginLeft: "80px"
    }
  }
}

export const nestedListIndent = {
  mobileL: "0px",
  mobileP: "0px",
  tabletP: "0px",
  tabletL: "16px"
}

export const secondNestedListIndent = {
  mobileL: "0px",
  mobileP: "0px",
  tabletP: "16px",
  tabletL: "16px"
}

export const decoratorSizes = {
  mobileP: "30px",
  mobileL: "30px",
  tabletP: "88px",
  tabletL: "88px",
  desktop: "88px",
  wide: "108px"
};
