import parse, { domToReact } from "html-react-parser";
import { merge, sortBy, uniq } from "lodash";
import { Link } from "react-router-dom";
import HtmlDiff from "htmldiff-js";
import { routes } from "./routes";

const CONTENT_ANCHOR = "content";
const MATCH_TEMPLATE_REVERSED = ">naps/<$1>\"hctam\"=ssalc naps<";
const REGEX_ENRICH = "[\\.\\u2012-\\u2014-]?";
const REGEX_LEGAL = /[\wäöüÄÖÜß.\u2012-\u2014-]*/gi;
const REGEX_NUMERATION = /(^[\d.]+)([\s]*)([^<^\s^.^\d])/g;

const nonPhrasingTags = ["p", "ul", "ol"];
// const allTags = [...nonPhrasingTags, "a", "strong", "i", "u", "li", "span"];
// const validContent = [...allTags, "&npsp;", " "];

const parseDate = (date, useFullMonth) => {
  if (date && date.toLocaleDateString) {
    return date.toLocaleDateString("de", {
      month: useFullMonth ? "long" : "2-digit",
      day: useFullMonth ?  "numeric" : "2-digit",
      year: "numeric"
    });
  }
  return date;
};

const parseContent = (text, isExpanded = true) => {
  if (!text) {
    return null;
  }
  const options = {
    replace: (node) => {
      const { name, attribs, children, type } = node;
      if (type === "tag") {
        // add link symbol, transform to react link if internal
        if (name === "a" && attribs.href) {
          let linkProps = {};
          linkProps.tabIndex = isExpanded ? "0" : "-1";
          if (attribs.href.match(new RegExp(`^/?${routes.faq.path}`, "i"))) {
            linkProps.className = "faq-link";
          }
          if (attribs.href.match(/^[a-z]*:/i)) {
            // external link
            return (
              <a href={attribs.href} {...linkProps}>
                {domToReact(children)}
              </a>
            );
          }

          // if the linked anchor is not existing in the document, the link
          // should be open in a new tab
          // not working if content is loaded at the same time
          // const linkedAnchor = document.getElementById(attribs.href.replace("#", ''));
          // if (!linkedAnchor) {
          //   linkProps.target = "_blank"
          // }
          return (
            <Link to={attribs.href} {...linkProps}>
              {domToReact(children)}
            </Link>
          );
        }
        // detect illegal nesting (very basic)
        if (name === "span") {
          if (children && children.length > 0) {
            let isNestingIllegal = false
            children.forEach((child) => {
              if (child.type === "tag" && nonPhrasingTags.includes(child.name)) {
                isNestingIllegal = true;
                // move attribs down, so outer tag can be removed
                child.attribs = merge(child.attribs, attribs);
              }
            });
            if (isNestingIllegal) {
              return domToReact(children);
            }
          }
        }
      }
    }
  };
  return parse(text, options);
};

// mark text with reversed regex
const getMatchReversed = (input, regEx) => {
  if (input) {
    const reversedInput = reverse(input);
    const match = reversedInput.match(regEx);
    if (match) {
      return {
        count: match.length,
        text: reverse(reversedInput.replace(regEx, MATCH_TEMPLATE_REVERSED))
      }
    }
  }
  return {
    count: 0,
    text: input
  };
}

const getDiff = (text1, text2) => {
  const diffText = HtmlDiff.execute(text2, text1);

  // remove diffs without change
  const cleanedText = diffText.replace(
    /<del class="diffmod">([^<]*)<\/del><ins class="diffmod">([^<]*)<\/ins>/g,
    (match, g1, g2) => {
      if (g1 === g2) {
        return g1;
      }
      return match;
    }
  );
  return cleanedText;
};

const containsDiff = (text) => {
  return !!text.match(/<del|<ins/g);
};

const getMediaDiff = (media1, media2) => {
  const mediaMap = {};
  const m1Map = {};
  media1.forEach((media) => {
    m1Map[`${media.id}`] = { ...media };
  });

  media2.forEach((media) => {
    const { id, text: text2, ...rest2 } = media;
    if (m1Map[id]) {
      // exists in both, merge text, keep content of media 1
      // show diff if url has changed
      const { text, ...rest1 } = m1Map[`${id}`];
      mediaMap[`${id}`] = {
        text: getDiff(text || "", rest1.url !== rest2.url ? "" : text2 || ""),
        ...rest1
      };
    } else {
      // exists m2 only
      mediaMap[`${id}`] = {
        id,
        text: getDiff("", text2 || ""),
        ...rest2
      };
    }
  });
  Object.values(m1Map).forEach(({ id, text, ...rest }) => {
    if (!mediaMap[`${id}`]) {
      // exists p1 only
      mediaMap[`${id}`] = {
        id,
        text: getDiff(text || "", ""),
        ...rest
      };
    }
  });

  return Object.values(mediaMap);
};

const getParagraphsDiff = (paragraphs1, paragraphs2) => {
  const paragraphMap = {};
  const p1Map = {};
  paragraphs1.forEach((paragraph) => {
    p1Map[paragraph.anchor] = { ...paragraph };
  });

  paragraphs2.forEach((paragraph) => {
    const { anchor, text: text2, headline: headline2, media: media2, ...rest } = paragraph;
    if (p1Map[anchor]) {
      // exists in both, merge
      const { text, headline, media } = p1Map[anchor];
      paragraphMap[anchor] = {
        anchor,
        text: getDiff(text || "", text2 || ""),
        headline: getDiff(headline || "", headline2 || ""),
        media: getMediaDiff(media || [], media2 || []),
        ...rest
      };
    } else {
      // exists p2 only
      paragraphMap[anchor] = {
        anchor,
        text: getDiff("", text2 || ""),
        headline: getDiff("", headline2 || ""),
        media: media2.map(({ text: mText, ...m }) => ({ text: getDiff("", mText || ""), ...m })),
        ...rest
      };
    }
  });
  Object.values(p1Map).forEach(({ anchor, text, headline, media, ...rest }) => {
    if (!paragraphMap[anchor]) {
      // exists p1 only
      paragraphMap[anchor] = {
        anchor,
        text: getDiff(text || "", ""),
        headline: getDiff(headline || "", ""),
        media: media.map(({ text: mText, ...m }) => ({ text: getDiff(mText || "", ""), ...m })),
        ...rest
      };
    }
  });

  const { paragraphs } = sortParagraphs({ paragraphs: Object.values(paragraphMap) });
  return paragraphs;
};

const scrollToAnchor = (anchor, offsetTop, behavior = "smooth") => {
  const el = document.getElementById(anchor);
  if (el) {
    const { top } = el.getBoundingClientRect();
    window.scrollTo({
      left: 0,
      top: window.pageYOffset + top - offsetTop - (anchor === CONTENT_ANCHOR ? 0 : 10),
      behavior
    });
  }
};

/**
 * Sort content.paragraphs by anchor suffix, e.g. p_1 < p_1_1 < p_3 < p_11
 * @param {Object} content
 */
const sortParagraphs = (content) => {
  if (content.paragraphs) {
    content.paragraphs = sortBy(content.paragraphs, [
      ({ anchor }) => {
        const segments = anchor.split("_").reverse();
        // divides each segment by power of increasing 100 to create value, e.g. p_2_10_3 => (float) 2.1003, p_3 => (float) 3
        const val = segments.reduce((acc, seg, index) => {
          let num = parseInt(seg);
          if (isNaN(num)) {
            return acc;
          }
          return acc / Math.pow(100, index) + num;
        }, 0);
        return val;
      }
    ]);
  }
  return content;
};
/**
 * Sort versions.sections by date descending
 * @param {Object} content
 * @param {Boolean} parseDate store parsed date if true
 * @returns {Object} content
 */
const sortVersions = (content, parseDate) => {
  if (content.versions) {
    content.versions = sortBy(content.versions, [
      (version) => {
        const { date } = version;
        if (date !== undefined) {
          const d = new Date(date);
          const t = d.getTime();
          if (!isNaN(t)) {
            if (parseDate) {
              version.date = d;
            }
            return -t;
          }
        }
        return 0;
      }
    ]);
  }
  return content;
};

/**
 * Sort content.sections by headline enumeration of latest version, e.g 3.10.2 > 3.10.1 > 2. Applies recursively to sub-sections
 * @param {Object} content
 * @param {Boolean} parseDate store parsed date if true
 * @returns {Object} content
 */
const sortSections = (content, parseDate) => {
  if (content.versions) {
    sortVersions(content, parseDate);
  }
  if (content.sections) {
    content.sections.forEach((section) => sortSections(section, parseDate));
    content.sections = sortBy(content.sections, [
      ({ versions }) => {
        if (versions && versions[0]) {
          const { headline } = versions[0];
          if (headline) {
            const segments = headline.split(".");
            // divides each segment by power of increasing 100 to create value, e.g. 3.12.4 => (float) 3.1204
            const val = segments.reduce((acc, seg, index) => {
              let num = parseInt(seg);
              if (isNaN(num)) {
                return acc;
              }
              return acc + num / Math.pow(100, index);
            }, 0);
            return val;
          }
        }
        return 1000;
      }
    ]);
  }
  return content;
};

const reverse = (text, sanitize, enrich) => {
  if (!text) {
    return text;
  }
  const rev = [...text].reverse();
  const san = sanitize ? sanitizeCharacterArray(rev) : rev;
  return san.join(enrich ? REGEX_ENRICH : "");
}

const sanitizeCharacterArray = (arr) => arr.filter((c) => c.match(REGEX_LEGAL)).map((c) => c.replace(".", "\\."));

const enrichRegEx = (text) => text ? sanitizeCharacterArray([...text]).join(REGEX_ENRICH) : text;

// find words in text with reversed regex
const findWordsInTextReversed = (regEx, text) => {
  if (text) {
    const words = reverse(text).match(regEx);
    if (words) {
      return uniq(words).map((w) => reverse(w));
    }
  }
  return [];
}

const insertTabStop = (text, preserveSpace) => {
  if(text.match(REGEX_NUMERATION)){
    if (preserveSpace) {
      return {
        text: text.replace(REGEX_NUMERATION, "$1$2&#09;$3"),
        hasTabStop: true
      };
    }
    return {
      text: text.replace(REGEX_NUMERATION, "$1&#09;$3"),
      hasTabStop: true
    };
  }
  return {
    text,
    hasTabStop: false
  };
}

const shorten = (text, length) => {
  if (text.length > length) {
    return `${text.slice(0, text.lastIndexOf(" ", length-3))}...`
  }
  return text;
}

const enrichPost = (post) => {
  if (post) {
    post.path = (post.slug || post.headline).replace(/\s/g, "_").replace(/\W/ig, "").toLowerCase();
    post.abstractShort = shorten(post.abstract, 200);
  }
  return post;
}

const enrichContent = (cnt) => {
  if (cnt.date) {
    cnt.date = new Date(cnt.date);
  }
  if (cnt.paragraphs) {
    cnt.paragraphs.forEach((paragraph) => {
      if (paragraph.media) {
        paragraph.media.forEach((m) => {
          const details = m.mime === "application/pdf" ? ` (${Math.round(m.size)} kb)` : "";
          m.text = `${m.alternativeText || m.name}${details}`;
        });
      }
    });
  }
  return cnt;
}
export {
  containsDiff,
  enrichContent,
  enrichPost,
  enrichRegEx,
  findWordsInTextReversed,
  getDiff,
  getMatchReversed,
  getParagraphsDiff,
  insertTabStop,
  parseDate,
  parseContent,
  sortParagraphs,
  reverse,
  sortSections,
  scrollToAnchor,
  CONTENT_ANCHOR
};
