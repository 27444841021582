import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { fontFamily, fontStyleH3Large } from "../utils/theme";

const Comp= styled.h3(({ resolutionCoarse, isGTETabletP }) => {
  return {
    display: "flex",
    marginBlockEnd: 0,
    marginBlockStart: 0,
    fontFamily: fontFamily.normal,
    marginBottom: isGTETabletP ? "11px" : "31px",
    ...fontStyleH3Large[resolutionCoarse],
    justifyContent: isGTETabletP ? "start" : "center",
    width: isGTETabletP ? "auto" : "100%"
  }
});

function H3LargeMobileCenter({ children }) {
  const { resolutionCoarse, isGTETabletP } = useViewerClient();
  return (
    <Comp resolutionCoarse={resolutionCoarse} isGTETabletP={isGTETabletP} >{ children }</Comp>
  );
}

export default H3LargeMobileCenter;
