import React, { createContext, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import { sum } from "lodash";
import { breakpoints, vSpacing } from "../utils/theme";

export const ViewerClientContext = createContext({
  resolution: "mobileP"
});

export const ViewerClientProvider = ({ children }) => {
  let resolution = "mobileP";
  let resolutionCoarse = "mobile";
  const isGTEMobileL = useMediaQuery({
    minWidth: breakpoints.mobileL
  });
  if (isGTEMobileL) {
    resolution = "mobileL";
  }
  const isGTETabletP = useMediaQuery({
    minWidth: breakpoints.tabletP
  });
  if (isGTETabletP) {
    resolution = "tabletP";
    resolutionCoarse = "tablet";
  }
  const isGTETabletL = useMediaQuery({
    minWidth: breakpoints.tabletL
  });
  if (isGTETabletL) {
    resolution = "tabletL";
  }
  const isGTEDesktop = useMediaQuery({
    minWidth: breakpoints.desktop
  });
  if (isGTEDesktop) {
    resolution = "desktop";
    resolutionCoarse = "desktop";
  }
  const isGTEWide = useMediaQuery({
    minWidth: breakpoints.wide
  });
  if (isGTEWide) {
    resolution = "wide";
  }

  const ticker = 0;
  //  isGTEDesktop ? tickerHeight : tickerHeightMobile;

  const headerHeight = sum(Object.values(vSpacing.header[resolutionCoarse]));

  return (
    <ViewerClientContext.Provider
      value={{
        resolution,
        resolutionCoarse,
        isGTEMobileL,
        isGTETabletP,
        isGTETabletL,
        isGTEDesktop,
        isGTEWide,
        tickerHeight: ticker,
        headerHeight: headerHeight
      }}
    >
      {children}
    </ViewerClientContext.Provider>
  );
};

ViewerClientProvider.propTypes = {
  children: PropTypes.node,
};

export const useViewerClient = () => {
  return useContext(ViewerClientContext);
};

/**
 * Helper to apply breakpoints for styles
 */
export function withBreakpoints({desktop, tablet, mobile, ...styles}) {
  const breakpoints = {desktop, tablet, mobile}
  return ({ resolutionCoarse }) => {
    return {
      ...styles,
      ...(breakpoints[resolutionCoarse] || {})
    }
  }
}
