import React from "react";
import styled from "styled-components";
import { backgrounds, decoratorSizes } from "../utils/theme";
import ContentWrapper from "./ContentWrapper";
import ArrowRightUpIcon from "./ArrowRightUpIcon";
import ArrowRightIcon from "./ArrowRightIcon";
import { useViewerClient } from "../context/viewerClient";

const Comp = styled.div(({ styles }) => ({
  width: "100%",
  position: "relative",
  ...styles
}));

const Row = styled.div(({ styles, useFlex }) => ({
  width: "100%",
  display: useFlex ? "flex" : "inherit",
  justifyContent: "center",
  ...styles
}));

const Marginal = styled.div({
  flex: "1 1 0px",
  position: "relative"
});

const DecoratorWrapper = styled.div(({ styles }) => ({
  ...styles
}));

function Container({
  theme = "transparent",
  left,
  right,
  fullWidth,
  decorator,
  containerStyles = {},
  rowStyles = {},
  contentStyles = {},
  contentSpacings,
  children
}) {
  const { isGTEDesktop, isGTETabletP, resolution } = useViewerClient();

  const showMarginals = fullWidth && isGTEDesktop;
  let showMobileDecorator = false;

  if (decorator) {
    let Decorator = null;
    const wrapperStyles = {};

    if (decorator === "ArrowRightUp") {
      Decorator = ArrowRightUpIcon;

      if (isGTEDesktop) {
        wrapperStyles.position = "absolute";
        wrapperStyles.right = "25%";
        wrapperStyles.top = "60px";
      } else if (isGTETabletP){
        wrapperStyles.svg = {
          transform: "rotate(90deg)"
        };
        showMobileDecorator = true;
      } else {
        showMobileDecorator = true;
        wrapperStyles.position = "relative";
        wrapperStyles.left = 8;
        wrapperStyles.top = -8;
        wrapperStyles.svg = {
          transform: "rotate(90deg) translate(15px,10px)"
        };
      }
    } else {
      Decorator = ArrowRightIcon;
      wrapperStyles.position = "absolute";
    }
    left = (
      <DecoratorWrapper styles={wrapperStyles}>
        <Decorator size={decoratorSizes[resolution]} />
      </DecoratorWrapper>
    );
  }

  return (
    <Comp styles={{
      background: backgrounds[theme],
      ...containerStyles
    }}>
      {showMobileDecorator ? left : null}
      <Row styles={rowStyles} useFlex={isGTETabletP}>
        {showMarginals && (
          <Marginal>
            {left}
          </Marginal>
        )}
        <ContentWrapper containerSpacings={contentSpacings} styles={{
          ...contentStyles
        }}>
          {/* TODO cols */}
          {children}
        </ContentWrapper>
        {showMarginals && (
          <Marginal>
            {right}
          </Marginal>
        )}
      </Row>
    </Comp>
  );
}

export default Container;
