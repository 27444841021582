import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { fontWeight } from "../utils/theme";
import ProcessStepDesktop from "./ProcessStepDesktop";

const compStyles = {
  mobile: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "88px"
  },
  tablet: {
    paddingTop: "58px"
  },
  desktop: {
    paddingTop: "58px"
  }
};

const Comp = styled.div(({ resolutionCoarse }) => {
  return {
    fontWeight: fontWeight.medium,
    ...(compStyles[resolutionCoarse] || null)
  };
});


function Process() {
  const { resolutionCoarse } = useViewerClient();
  return (
    <Comp resolutionCoarse={resolutionCoarse}>
        <ProcessStepDesktop />
    </Comp>
  );
}

export default Process;
