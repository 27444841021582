import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { useViewerClient, withBreakpoints } from "../context/viewerClient";
import {
  colors,
  colorSchemes,
  fontStyleStage,
  fontWeight,
  hSpacing,
  themeButtonReset,
  transitionSpeed
} from "../utils/theme";
import Container from "./Container";
import StagePattern from "./StagePattern";

const minHeights = {
  mobile: 430,
  tablet: 470,
  desktop: 470
};

const Comp = styled.div(({ headerHeight, resolutionCoarse }) => {
  return {
    height: `calc(100vh - ${headerHeight}px)`,
    minHeight: `${minHeights[resolutionCoarse]}px`,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "@media print": {
      display: "none"
    }
  };
});

const CtaArea = styled.div(() => ({
  flex: "0 0 auto",
  textAlign: "center",
  padding: "16px"
}));

const PatternWrapper = styled.div(() => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: -1,
  overflow: "hidden"
}));

const TextWrapper = styled.div(({ resolutionCoarse }) => ({
  paddingLeft: `${
    hSpacing.page[resolutionCoarse].left +
    hSpacing.content[resolutionCoarse].left -
    hSpacing.content[resolutionCoarse].right
  }px`,
  paddingRight: `${hSpacing.page[resolutionCoarse].right}px`,
  height: "100%",
  display: "flex",
  flexDirection: "column-reverse",
  alignItems: "flex-end"
}));

const TextInner = styled.div(({ resolutionCoarse }) => ({
  ...fontStyleStage[resolutionCoarse],
  color: colors.BLACK,
  textAlign: "right",
  // maxWidth: "800px",
  width: "90%",
  paddingLeft: `${hSpacing.colGap[resolutionCoarse] / 2}px`,
  position: "relative"
}));

const Claim = styled.div`
  position: absolute;
  right: 0;
  bottom: 48px;
`;

const ScrollLink = styled.button(withBreakpoints({
    ...themeButtonReset,
    fontWeight: fontWeight.medium,
    color: colorSchemes.light.color,
    lineHeight: 1.22,
    fontSize: "20px",
    desktop: {
      fontSize: '23px'
    },
    ":hover .anim": {
      transform: "translate(0,5px)"
    }
}));

const ScrollLinkText = styled.div({
  display: "block"
});

const ScrollLinkArrow = styled.div({
  transitionDuration: `${transitionSpeed}s`
});

const WhiteCircle = styled.img(withBreakpoints({
  width: 'auto',
  height: "86%",
  position: 'absolute',
  top: '10.9%',
  right: '0',
  transform: 'translateX(47%)',
  zIndex: -1,
  mobile: {
    transform: 'translateX(55%)',
  }
}));

const LightSpot = styled.span(withBreakpoints({
  width: '61%',
  paddingBottom: '61%',
  background: 'transparent radial-gradient(closest-side at 50% 50%, #FFFFFF80 0%, #FFFFFF4D 31%, #FFFFFF23 62%, #FFFFFF0A 86%, #FFFFFF00 100%) 0% 0% no-repeat padding-box',
  position: 'absolute',
  top: '0',
  left: '0',
  transform: 'translateY(-61%)',
  zIndex: -1,
    mobile: {
      width: '100%',
    }
}))

function Stage({ onScroll }) {
  const { t } = useTranslation();
  const { resolutionCoarse, headerHeight, tickerHeight } = useViewerClient();

  return (
    <Comp headerHeight={headerHeight + tickerHeight} resolutionCoarse={resolutionCoarse}>
      <Container containerStyles={{ flex: "0 0 auto" }}>
      </Container>
      <Container
        containerStyles={{ flex: "1 1 auto", height: "100%", position: 'relative'}}
        rowStyles={{ height: "100%" }}
        contentStyles={{ height: "100%" }}
      >
        <PatternWrapper>
          <StagePattern/>
        </PatternWrapper>
        <TextWrapper resolutionCoarse={resolutionCoarse}>
          <TextInner resolutionCoarse={resolutionCoarse}>
            <Claim><Trans i18nKey={`header.stageText`}>...</Trans></Claim>
          </TextInner>
        </TextWrapper>
        <WhiteCircle resolutionCoarse={resolutionCoarse} src="/circle_plane.svg" alt="" />
        <LightSpot resolutionCoarse={resolutionCoarse} />
      </Container>
      <CtaArea>
        <ScrollLink onClick={() => onScroll()} resolutionCoarse={resolutionCoarse}>
          <ScrollLinkText>{t("header.scrollCTA")}</ScrollLinkText>
          <ScrollLinkArrow className="anim">↓</ScrollLinkArrow>
        </ScrollLink>
      </CtaArea>
    </Comp>
  );
}

export default Stage;
