import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useContent } from "../context/content";
import { useStyles } from "../context/styles";
import { useViewerClient } from "../context/viewerClient";
import { insertTabStop, parseContent } from "../utils/content";
import {
  indentAccordionLeftMobile,
  indentAccordionLeftDesktop,
  indentAccordionRight,
  containerStylesAccordion,
  fontStyleAccordion,
  themeHeadline,
  transitionSpeed,
  diffStyles,
  tabSize,
  numIndent
} from "../utils/theme";
import Accordion from "./Accordion";
import AccordionControl from "./AccordionControl";
import Column from "./Column";

const Comp = styled.div(({ noBorder }) => {
  if (noBorder) {
    return {};
  }
  return themeHeadline.underline;
});

const AccSectionPane = styled.div({
  position: "relative",
  display: "flex"
});

const AccContentPane = styled.div({
  flexDirection: "column",
  flex: "1 1 auto",
  wordBreak: "break-word"
});

const AccHeadline = styled.div(({ resolutionCoarse, level }) => {
  return {
    display: "flex",
    cursor: "pointer",
    paddingLeft: resolutionCoarse === "mobile" ? `${indentAccordionLeftMobile}px` : `${indentAccordionLeftDesktop}px`,
    paddingRight: `${indentAccordionRight}px`,
    ...diffStyles,
    ...fontStyleAccordion[level][resolutionCoarse]
  };
});

const AccContent = styled.div(({ resolutionCoarse, level, isExpanded, isEnumerated }) => {
  const collapsedStyles = isExpanded ? { maxHeight: "100000px" } : { maxHeight: 0 };
  const indentStyles = {};

  if (resolutionCoarse === "mobile") {
    // use same padding as in headline
    indentStyles.paddingLeft = `${indentAccordionLeftMobile}px`;
    indentStyles.paddingRight = `${indentAccordionRight}px`;
  } else {
    // use headline padding as margin in order to use headline indent as padding
    indentStyles.marginLeft = `${indentAccordionLeftDesktop}px`;
    indentStyles.marginRight = `${indentAccordionRight}px`;
    if (isEnumerated) {
      // use font size and weight for accurate character based padding
      const { fontSize, fontWeight } = fontStyleAccordion[level][resolutionCoarse];
      const currentNumIndent = level ? numIndent.l1 : numIndent.l0;
      indentStyles.fontSize = fontSize;
      indentStyles.fontWeight = fontWeight;
      indentStyles.paddingLeft = `${currentNumIndent}ch`;
    }
  }
  return {
    overflow: "hidden",
    transition: `max-height ${transitionSpeed}s`,
    height: "100%",
    maxWidth: "1060px",
    ...indentStyles,
    ...collapsedStyles
  };
});

const HeadlineText = styled.span(({ level, resolutionCoarse, isEnumerated }) => {
  const { isSafari, isFirefoxGTE91 } = useStyles();
  if (!isEnumerated || (level && resolutionCoarse === "mobile")) {
    // no indent on second level on mobile
    return {};
  }
  const currentTabSize = level ? tabSize.l1 : tabSize.l0;
  const currentNumIndent = level ? numIndent.l1 : numIndent.l0;
  return {
    "-mozTabSize": `${currentTabSize}ch`,
    // firefox 91+ does not recognize prefixed style anymore
    tabSize: isSafari || isFirefoxGTE91 ? `${currentNumIndent}ch` : `${currentTabSize}ch`,
    textIndent: `-${currentNumIndent}ch`,
    paddingLeft: `${currentNumIndent}ch`,
    whiteSpace: "pre-wrap"
  };
});

const columnStyles = {
  transitionDuration: `${transitionSpeed}s`,
  height: 0
};

const AccordionSection = ({
  isExpanded: isExpandedSingle,
  sectionKey,
  level,
  toggle,
  scrollTo,
  noBorder,
  headline,
  paragraphs,
  sections,
  id,
  contentPath,
  hasContentDiff,
  sectionsDiffCount,
  versionIds,
  useFilter,
  openMulti
}) => {
  const { resolutionCoarse } = useViewerClient();

  const [openMultiState, setOpenMultiState] = useState(openMulti);
  const { contents, fetchContents } = useContent();
  const [paragraphsContent, setParagraphsContent] = useState(paragraphs || null);
  const [isExpanded, setIsExpanded] = useState(!level);

  const handleOnHeadlineClick = (sectionKey) => {
    // open state handled externally
    if (!openMulti) {
      // open state handled externally, propagate
      toggle(sectionKey);
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    // open state handled externally, keep in sync
    if (!openMulti && isExpanded !== isExpandedSingle) {
      setIsExpanded(isExpandedSingle);
    }
  }, [isExpanded, isExpandedSingle, openMulti]);

  useEffect(() => {
    // open state handled externally, keep in sync
    if (openMulti !== openMultiState) {
      setOpenMultiState(openMulti);
      if (openMulti) {
        setIsExpanded(!level);
      }
    }
  }, [isExpanded, level, openMulti, openMultiState]);

  useEffect(() => {
    // open state handled externally, keep in sync
    if (paragraphs) {
      setParagraphsContent(paragraphs);
    } else {
      setParagraphsContent(null);
    }
  }, [paragraphs, setParagraphsContent]);

  const columnStylesExpanded = {
    ...containerStylesAccordion[level][resolutionCoarse],
    transitionDuration: `${transitionSpeed}s`,
    height: "100%"
  };

  // get content from provider
  if (isExpanded && !paragraphsContent && id) {
    if (id) {
      if (contents[contentPath] && contents[contentPath][id]) {
        setParagraphsContent(contents[contentPath][id].paragraphs);
      } else if (!versionIds) {
        fetchContents([id], contentPath, true);
      }
    }
  }

  let hasDiff = hasContentDiff || !!sectionsDiffCount;
  const accSectionBorder = isExpanded && level === 0 ? themeHeadline.underline.borderBottom : "none";

  const { text: headlineText, hasTabStop } = insertTabStop(headline);

  return (
    <Comp noBorder={noBorder}>
      <AccSectionPane style={{ borderBottom: accSectionBorder, borderTop: "none" }}>
        <AccContentPane>
          <AccHeadline
            resolutionCoarse={resolutionCoarse}
            level={level}
            onClick={() => handleOnHeadlineClick(sectionKey)}
            id={sectionKey}
            role="button"
          >
            <HeadlineText level={level} resolutionCoarse={resolutionCoarse} isEnumerated={hasTabStop}>
              {parseContent(headlineText)}
            </HeadlineText>
          </AccHeadline>
          <AccContent resolutionCoarse={resolutionCoarse} level={level} isExpanded={isExpanded} isEnumerated={hasTabStop}>
            {paragraphsContent && paragraphsContent.length > 0 && (
              <Column
                isExpanded={isExpanded}
                content={{ paragraphs: paragraphsContent }}
                columnStyles={isExpanded ? columnStylesExpanded : columnStyles}
              />
            )}
          </AccContent>
        </AccContentPane>
        <AccordionControl
          isExpanded={isExpanded}
          sectionKey={sectionKey}
          level={level}
          onToggle={handleOnHeadlineClick}
          onScrollTo={scrollTo}
          highlight={hasDiff}
          useFilter={useFilter}
        />
      </AccSectionPane>
      {isExpanded && sections && (
        <Accordion
          sections={sections}
          prefix={sectionKey}
          level={level + 1}
          useFilter={useFilter}
          openMulti={openMulti}
          contentPath={contentPath}
        />
      )}
    </Comp>
  );
};

export default AccordionSection;
