import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink as Link  } from "react-router-dom";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { metaNavLinks } from "../utils/routes";
import { colorSchemes, containerWidthsText, contentSpacing, fontSize, fontWeight } from "../utils/theme";
import BundIcon from "./BundIcon";
import Container from "./Container";
import FHHIcon from "./FHHIcon";
import NavLinkItem from "./NavLinkItem";
import SponsorLogoWrapper from "./SponsorLogoWrapper";
import ArrowRightIcon from "./ArrowRightIcon";

const Comp = styled.div({
  "@media print": {
    display: "none"
  }
});
const Title = styled.div({
  fontWeight: fontWeight.regular,
  fontSize: fontSize.d30x,
  paddingTop: "32px",
  paddingBottom: "32px"
});
const ContentContainer = styled.div({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between"
});
const SponsorLogoContainer = styled.div(({ isMobile }) => ({
  flex: "0 1 auto",
  display: "flex",
  paddingBottom: "10px",
  marginRight: isMobile ? 0 : "50px"
}));
const MetaNavContainer = styled.ul({
  flex: "0 1 auto",
  display: "flex",
  flexWrap: "wrap",
  margin: 0,
  color: colorSchemes.dark.color,
  li: {
    marginRight: "20px"
  },
  "li:last-child": {
    marginRight: 0
  }
});

function Footer({ fwRef }) {
  const { t } = useTranslation();
  const { resolution, isGTETabletP } = useViewerClient();
  return (
    <Comp ref={fwRef}>
      <Container
        theme="black"
        contentSpacings={containerWidthsText}
        contentStyles={{
          fontSize: fontSize.d30x,
          fontWeight: fontWeight.semiBold,
          lineHeight: "24px",
          color: colorSchemes.dark.color,
          ".fill": {
            fill: colorSchemes.dark.color
          }
        }}
        containerStyles={{ zIndex: 2 }}
      >
        <Title>{t("footer.title")}</Title>
        <ContentContainer>
          <SponsorLogoContainer isMobile={!isGTETabletP}>
            <SponsorLogoWrapper isFirst={true}>
              <BundIcon />
            </SponsorLogoWrapper>
            <SponsorLogoWrapper>
              <FHHIcon />
            </SponsorLogoWrapper>
          </SponsorLogoContainer>
          <MetaNavContainer>
            {metaNavLinks.map(({ key, path, hash }) => (
              <NavLinkItem key={`b_nav_${key}`} colorScheme={colorSchemes.dark}>
                {
                  path.startsWith('https:') && <Link
                    to={{pathname: path}}
                    activeStyle={{
                      textDecoration: "underline"
                    }}
                    target="_blank"
                  >
                    <ArrowRightIcon/> {t(`nav.${key}`)}
                  </Link>
                }
                {
                  !path.startsWith('https:') && <Link
                    to={`${path ? `/${path}` : ""}${hash ? `/#${hash}` : ""}`}
                    activeStyle={{
                      textDecoration: "underline"
                    }}
                  >
                    {t(`nav.${key}`)}
                  </Link>
                }
                
              </NavLinkItem>
            ))}
          </MetaNavContainer>
        </ContentContainer>
      </Container>
      <Container
        theme="white"
        contentStyles={{
          height: "64px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          fontSize: fontSize.d10x,
          fontStyle: "italic",
          textAlign: "center",
          paddingLeft: contentSpacing[resolution],
          paddingRight: contentSpacing[resolution],
          zIndex: 2
        }}
        fullWidth
      >
        {t("footer.copyright")}
      </Container>
    </Comp>
  );
}
export default Footer;
