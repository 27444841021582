import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { colorSchemes, fontStyleTextBoxHeadline, fontWeight, themeHeadline } from "../utils/theme";
import ArrowDownIcon from "./ArrowDownIcon";
import ArrowLeftIcon from "./ArrowLeftIcon";
import Column from "./Column";

const Comp = styled.div(({ resolutionCoarse }) => {
  return { };
});

const HeadlineWrapper = styled.div(({ resolutionCoarse }) => {
  return {
    display: "flex",
    fontWeight: fontWeight.bold,
    marginBlockEnd: "1em",
    paddingRight: "0.5em",
    cursor: "pointer",
    ...themeHeadline.underline,
    ...fontStyleTextBoxHeadline[resolutionCoarse]
  };
});

const Headline = styled.div({
  flex: "1 1 auto"
});

const Control = styled.div({
  flex: "0 0 22px",
  paddingLeft: "10px",
  fontWeight: "normal",
  fontSize: "1.5em"
});

const TextBox = ({ headline, paragraphs }) => {
  const { resolutionCoarse } = useViewerClient();

  const [isExpanded, setIsExpanded] = useState(true);
  const toggle = useCallback(
    () => {
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  return (
    <Comp>
      {headline && (
        <HeadlineWrapper
          resolutionCoarse={resolutionCoarse}
          onClick={() => toggle()}
          role="button"
        >
          <Headline>{headline}</Headline>
          <Control>
            {isExpanded ? (
              <ArrowDownIcon fill={colorSchemes.light.color} />
            ) :(
              <ArrowLeftIcon fill={colorSchemes.light.color} />
            )}
          </Control>
        </HeadlineWrapper>
      )}
      {isExpanded &&
        <Column content={{ paragraphs }}/>
      }
    </Comp>
  );
};

export default TextBox;
