const CONTENT_ANCHOR = "content";

export const routes = {
  // accessiblity: {
  //   path: "barrierefreiheit",
  //   prefix: "home",
  //   component: Home // DataProtection,
  // },
  data: {
    path: "datenschutz",
    prefix: "home",
    component: "DataProtection",
  },
  faq: {
    path: "faq",
    prefix: "faq",
    component: "Faq",
  },
  implementation: {
    path: "implementation",
    prefix: "implementation",
    component: "SimpleContent",
    params: {
      prefix: "implementation"
    }
  },
  logo: {
    path: "https://www.kulturfonds-energie.de/static-files/Logo_Kulturfonds_Energie.zip",
    prefix: "logo"
  },
  legal: {
    path: "impressum",
    prefix: "legal",
    component: "Legal",
  },
  overview: {
    path: "",
    hash: `${CONTENT_ANCHOR}`,
    prefix: "home",
    component: "Home",
    isFallbackPage: true
  },
};

export const allRoutes = Object.values(routes);

export const fallback = routes.overview;

const navLinksOrder = [
  "overview",
  "faq",
  "implementation"
];

const metaNavLinksOrder = [
  "legal",
  "data",
  "logo"
  // "accessiblity"
];

export const navLinks = navLinksOrder.map((key) => ({ key, ...routes[key] }));
export const metaNavLinks = metaNavLinksOrder.map((key) => ({ key, ...routes[key] }));