import React from "react";
import { colors } from "../utils/theme";

function BurgerIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="18" viewBox="0 0 27 18">
      <path
        d="M4.5,27h27V24H4.5Zm0-7.5h27v-3H4.5ZM4.5,9v3h27V9Z"
        transform="translate(-4.5 -9)"
        fill={colors.BLACK}
      />
    </svg>
  );
}
export default BurgerIcon;
