import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { colors, colorSchemes, controlStyleAccordion, themeButtonReset } from "../utils/theme";
import ArrowDownIcon from "./ArrowDownIcon";
import ArrowLeftIcon from "./ArrowLeftIcon";
import ScrollLink from "./ScrollLink";

const Comp = styled.div(() => {
  return {
    position: "absolute",
    height: "100%",
    right: 0,
    paddingLeft: "10px",
    paddingRight: "22px",
    width: "26px"
  };
});

const AccControl = styled.button(({ resolutionCoarse, level, highlight, useFilter }) => {
  let background;
  if (useFilter) {
    background = colors.BLUE_MEDIUM;
  } else if (highlight) {
    background = colors.YELLOW_MEDIUM;
  } else {
    background = "transparent";
  }
  return {
    ...themeButtonReset,
    ...controlStyleAccordion[level][resolutionCoarse],
    background,
    position: "relative",
    width: "26px",
    fontWeight: "normal"
  }
});

const AccordionControl = ({ isExpanded, sectionKey, level, onToggle, onScrollTo, highlight, useFilter }) => {
  const { isGTETabletP, resolutionCoarse, headerHeight } = useViewerClient();

  return (
    <Comp isMobile={!isGTETabletP} resolutionCoarse={resolutionCoarse} level={level}>
      {isExpanded ? (
        <>
          {level ? (
            <ScrollLink rotateOnFix limitToBottom scrollOffset= {headerHeight}>
              {props => (
                <AccControl
                  onClick={() => props.fixTop ? onScrollTo(sectionKey) : onToggle(sectionKey)}
                  resolutionCoarse={resolutionCoarse}
                  level={level}
                  highlight={highlight}
                  useFilter={useFilter}
                  aria-expanded={isExpanded}
                  aria-labelledby={sectionKey}
                >
                  <ArrowDownIcon size={21} fill={colorSchemes.light.color} />
                </AccControl>
                )
              }
            </ScrollLink>
          ) : (
            <AccControl
              onClick={() => onToggle(sectionKey)}
              resolutionCoarse={resolutionCoarse}
              level={level}
              useFilter={useFilter}
              aria-expanded={isExpanded}
              aria-labelledby={sectionKey}
            >
              –
            </AccControl>
          )}
        </>
      ) : (
        <AccControl
          onClick={() => onToggle(sectionKey)}
          resolutionCoarse={resolutionCoarse}
          level={level}
          highlight={highlight}
          useFilter={useFilter}
          aria-expanded={isExpanded}
          aria-labelledby={sectionKey}
        >
          {level ? <ArrowLeftIcon size={21} fill={colorSchemes.light.color} /> : <>+</>}
        </AccControl>
      )}
    </Comp>
  );
};

export default AccordionControl;
