import styled from "styled-components";
import { colorSchemes } from "../utils/theme";

const InputGroup = styled.div(({ isMobile, styles }) => ({
  display: "flex",
  border: `2px solid ${colorSchemes.light.color}`,
  borderRadius: "4px",
  lineHeight: "47px",
  background: colorSchemes.light.background,
  marginBottom: "21px",
  ...styles
}));

export default InputGroup;
