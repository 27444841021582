const translation = {
  common: {
    expand: "Weiterlesen",
    register: "Anmelden",
    versionDate: "Aktueller Stand: <1> {{date}}</1>",
    versionDateOld: "Vergleichsbasis: <1> {{date}}</1>"
  },
  contentFilter: {
    compare: {
      cancel: "Vergleich zurücksetzen",
      compareTo: {
        desktop: "mit Stand vom:",
        mobile: "Stand vom:"
      },
      count: "<strong>{{count}}</strong> Fragen geändert",
      intro: "<strong>Vergleichen Sie</strong> den derzeit aktuellen Stand der FAQ vom <strong>{{date}}</strong> mit einem wählbaren Stand  als Vergleichsbasis. Inhaltliche Änderungen werden hervorgehoben.",
      lastVersion: " (Letzte Version)",
      placeholder: "Bitte wählen",
      result: "<0>Sie vergleichen</0> den derzeit aktuellen Stand der FAQ vom <2>{{date}}</2> mit dem Stand vom <4>{{dateOld}}</4> als Vergleichsbasis.<6> Inhaltliche Veränderungen </6> werden hervorgehoben.",
      selectAria: "Auswahlfeld zum Vergleich der Versionshistorie"
    },
    print: {
      action: "Drucken",
      headline: "Drucken",
      intro: "<strong>Drucken Sie</strong> den aktuellen Stand des FAQ vom <strong>{{date}}</strong> im Volltext und ohne Markierungen aus."
    },
    search: {
      action: "Suchen",
      count: "<strong>{{count}}</strong> Suchtreffer",
      clearAria: "Leeren des Eingabefeldes der Suche",
      noMatch: {
        summary: "<strong>0</strong> Suchtreffer",
        intro: "<strong>Sie durchsuchen</strong> die FAQ vom <strong>{{date}}.</strong> Ihr Suchbegriff <5>{{fullSearchString}}</5> wurde nicht im FAQ gefunden. Probieren Sie es mit einem anderen oder allgemeineren Suchbegriff.",
      },
      headline: "Durchsuchen",
      inputAria: "Eingabefeld für den Suchbegriff zur Suche",
      intro: "<strong>Sie durchsuchen</strong> die FAQ vom <strong>{{date}}.</strong> Unten werden nur die Fragen angeführt, in denen Ihre Suchtreffer auftauchen.<5> Das Ergebnis Ihrer Suche </5>wird in den aufgeklappten Antworten der Fragen hervorgehoben.",
      placeholder: {
        desktop: "Die Fragen und Antworten der FAQ durchsuchen",
        mobile: "FAQ durchsuchen"
      },
      suggestionListAria: "Vorschlagsliste der Suchergebnisse"
    },
    show: {
      headline: "Vergleichen"
    }
  },
  contact: {
    email: {
      text: "service@kulturfonds-energie.de"
    },
    faq: {
      text: "Details in den "
    },
    tel: {
      number: "+498006645685",
      text: "Service-Hotline 0800 6645685"
    },
    headline: "Sind noch Fragen offen?",
    headlineFAQ: "Sind noch Fragen offen?",
    subheadlineFAQ: "Kontaktieren Sie uns gerne"
  },
  footer: {
    copyright: "© Kulturfonds Energie des Bundes 2023",
    title: "Ein gemeinsames Angebot von:"
  },
  header: {
    home: "zur Startseite",
    scrollCTA: "Erfahren Sie mehr",
    stageText: "Kulturfonds Energie<br>des Bundes"//,
    //tickerContent: "Der Kulturfonds Energie des Bundes ist als Härtefallhilfe im Wirtschaftsstabilisierungsfonds (WSF) angelegt; für diesen wurde am 21.11.2023 eine Sperre gem. § 41 BHO angeordnet. Dies bedeutet, dass ab diesem Zeitpunkt Ausgaben und Verpflichtungen global gesperrt sind. Bereits bewilligte/ausgezahlte Anträge sind nicht zurückzuerstatten. Im Übrigen sind weitere Beschlüsse zum Bundeshaushalt abzuwarten; die Bundesregierung arbeitet derzeit intensiv an Lösungen.",
    //topbarText: "Der Kulturfonds Energie des Bundes ist als Härtefallhilfe im Wirtschaftsstabilisierungsfonds (WSF) angelegt; für diesen wurde am 21.11.2023 eine Sperre gem. § 41 BHO angeordnet. Dies bedeutet, dass ab diesem Zeitpunkt Ausgaben und Verpflichtungen global gesperrt sind. Bereits bewilligte/ausgezahlte Anträge sind nicht zurückzuerstatten. Im Übrigen sind weitere Beschlüsse zum Bundeshaushalt abzuwarten; die Bundesregierung arbeitet derzeit intensiv an Lösungen."
  },
  link: {
    apply: {
      text: "Anmelden →",
      textMobile: "Anmelden →"
    }
  },
  nav: {
    accessiblity: "Barrierefreiheit",
    data: "Datenschutz",
    faq: "FAQ",
    legal: "Impressum",
    implementation: "Vollzugshinweise",
    logo: "Logo-Download",
    menuClose: "Menü schließen",
    menuOpen: "Menü öffnen",
    overview: "Überblick",
    procedure: "Ablauf",
  },
  print: {
    date: "FAQs Stand vom:"
  },
  process: {
    after: {
      headline: "Antrag stellen",
      text1: "Anmelden",
      text2: "Angaben ausfüllen",
      text3: "Abschicken"
    },
    event: "Mehrbedarf Energiekosten",
    pre: {
      headline: "Nach Energienutzung"
    }
  }
};

export default translation;
