import React, { useState } from "react";
import ColumnsWrapper from "../components/ColumnsWrapper";
import { useContent } from "../context/content";
import { CONTENT_PATH } from "../context/versionedContent";

const ColumnsWithAPIContent = ({ sections, prefix }) => {
  const [columns, setColumns] = useState(null);
  const { contents, fetchContents } = useContent();

  if (!columns && sections && sections[0]) {
    const section = sections[0]
    if (section.id) {
      if (contents[CONTENT_PATH] && contents[CONTENT_PATH][section.id]) {
        setColumns([{
          headline: section.headline,
          paragraphs: contents[CONTENT_PATH][section.id].paragraphs
        }]);
      } else {
        fetchContents([section.id], CONTENT_PATH, true);
      }
    }
  }

  if (!columns) {
    return null;
  }

  return (
    <ColumnsWrapper columns={columns} />
  );
};

export default ColumnsWithAPIContent;
