import React, { createContext, useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { enrichContent, sortParagraphs, sortSections } from "../utils/content";

export const ContentContext = createContext({
  contents: {}
});

export const ContentProvider = ({ children }) => {
  const [contentLists, setContentLists] = useState({});
  const [contents, setContents] = useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchListDebounced = useCallback(
    debounce(
      (key) =>
        fetch(`${process.env.REACT_APP_CONTENT_ENDPOINT}${process.env.REACT_APP_CONTENT_LIST_PATH}/${key}${process.env.REACT_APP_API_SUFFIX}`).then(
          handleContentListResponse,
          handleContentListError
        ),
      500,
      { leading: true }
    ),
    [contentLists]
  );

  const handleContentListResponse = useCallback(
    (response) => {
      fetchListDebounced.cancel();
      if (response.ok) {
        response.json().then(({ key, ...cnt }) => setContentLists({ ...contentLists, [key]: sortSections(cnt, true) }));
      }
    },
    [contentLists, fetchListDebounced]
  );

  const handleContentListError = useCallback(
    (error) => {
      fetchListDebounced.cancel();
      console.log(`Error loading: ${error}`);
    },
    [fetchListDebounced]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchContentsDebounced = useCallback(
    debounce(
      async (keys, contentPath, setContent) => {
        return Promise.all(
          keys.map(async (key) => {
            if (!contents[contentPath] || !contents[contentPath][key]) {
              const response = await fetch(
                `${process.env.REACT_APP_CONTENT_ENDPOINT}${contentPath}/${key}${process.env.REACT_APP_API_SUFFIX}`
              );
              if (response.ok) {
                return await response.json();
              }
            }
            return null;
          })
        ).then((jsons) => {
          const newContents = handleContentsRespsonse(jsons, contentPath);
          if (setContent) {
            setContents(newContents);
          }
          return newContents;
        }, handelContentsError);
      },
      1000,
      { leading: true }
    ),
    []
  );

  const handleContentsRespsonse = useCallback(
    (jsons, contentPath) => {
      fetchContentsDebounced.cancel();
      const newContents = { ...contents };
      jsons.forEach((json) => {
        if (json) {
          const { id, ...cnt } = json;
          if (!newContents[contentPath]) {
            newContents[contentPath] = {};
          }
          enrichContent(cnt);
          newContents[contentPath][id] = sortParagraphs(cnt);
        }
      });
      return newContents;
    },
    [contents, fetchContentsDebounced]
  );

  const handelContentsError = useCallback(
    (error) => {
      fetchContentsDebounced.cancel();
      console.log(`Error loading: ${error}`);
    },
    [fetchContentsDebounced]
  );

  const fetchContentList = useCallback((key) => fetchListDebounced(key), [fetchListDebounced]);
  const fetchContents = useCallback((key, path, setContent) => fetchContentsDebounced(key, path, setContent), [fetchContentsDebounced]);

  return (
    <ContentContext.Provider
      value={{
        contents,
        fetchContents,
        setContents,
        contentLists,
        fetchContentList
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

ContentProvider.propTypes = {
  children: PropTypes.node
};

export const useContent = () => {
  return useContext(ContentContext);
};
