import React from "react";
import { colorSchemes } from "../utils/theme";

function UploadIcon({ size = 48, color = colorSchemes.light.color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
      <g transform="translate(-952.879 -179.621)">
        <rect width="48" height="48" transform="translate(952.879 179.621)" fill="none" />
        <path
          d="M980.879,183.621h-16a4,4,0,0,0-3.98,4l-.02,32a4,4,0,0,0,3.98,4h24.02a4.012,4.012,0,0,0,4-4v-24Zm8,36h-24v-32h14v10h10Zm-20-9.98,2.82,2.82,3.18-3.16v8.32h4V209.3l3.18,3.18,2.82-2.84-7.98-8.02Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
export default UploadIcon;
