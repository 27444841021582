import React from "react";
import { colorSchemes } from "../utils/theme";

function AddPersonIcon({ size = 48, color = colorSchemes.light.color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
      <g transform="translate(-60.566 -181.223)">
          <rect
            width="48"
            height="48"
            transform="translate(60.566 181.223)"
            fill="none"
          />
          <path
            d="M90.566,205.223a8,8,0,1,0-8-8A8,8,0,0,0,90.566,205.223Zm-18-4v-6h-4v6h-6v4h6v6h4v-6h6v-4Zm18,8c-5.34,0-16,2.68-16,8v4h32v-4C106.566,211.9,95.906,209.223,90.566,209.223Z"
            fill={color}
          />
      </g>
    </svg>
  );
}
export default AddPersonIcon;
