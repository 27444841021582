import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { transitionSpeed } from "../utils/theme";

const Comp = styled.div({
  position: "relative",
  width: "100%",
  height: "100%"
});
const Inner = styled.div(({ fixTop, fixBottom, rotateOnFix }) => {
  const fixedStyles = rotateOnFix
    ? {
        transitionDuration: `${transitionSpeed}s`,
        transform: "rotate(180deg)"
      }
    : {};
  const fallbackStyles = rotateOnFix
    ? {
        transitionDuration: `${transitionSpeed}s`
      }
    : {};

  if (fixBottom) {
    return {
      position: "absolute",
      bottom: 0,
      ...fixedStyles
    };
  }
  if (fixTop) {
    return {
      position: "fixed",
      top: `${fixTop}px`,
      ...fixedStyles
    };
  }
  return fallbackStyles;
});

const ScrollLink = ({ children, scrollOffset, rotateOnFix, limitToBottom }) => {
  const compRef = useRef(null);
  const innerRef = useRef(null);
  const [fixTop, setFixTop] = useState(0);
  const [fixBottom, setFixBottom] = useState(false);

  useLayoutEffect(() => {
    if (window && compRef.current) {
      const handleScroll = () => {
        const { y } = compRef.current.getBoundingClientRect();
        if (!fixTop) {
          if (y <= scrollOffset) {
            setFixTop(scrollOffset);
          }
        } else {
          if (y > scrollOffset) {
            setFixTop(0);
          }
        }
        if (limitToBottom && innerRef.current) {
          if (!fixBottom) {
            if (y + compRef.current.offsetHeight - innerRef.current.offsetHeight < scrollOffset) {
              setFixBottom(true);
            }
          } else {
            if (y + compRef.current.offsetHeight - innerRef.current.offsetHeight >= scrollOffset) {
              setFixBottom(false);
            }
          }
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  });

  return (
    <Comp ref={compRef}>
      <Inner ref={innerRef} fixTop={fixTop} fixBottom={fixBottom} rotateOnFix={rotateOnFix}>
        {children({ fixTop, fixBottom })}
      </Inner>
    </Comp>
  );
};

export default ScrollLink;
