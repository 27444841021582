import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router";
import { CONTENT_ANCHOR, scrollToAnchor } from "../utils/content";
import { useViewerClient } from "../context/viewerClient";
import Footer from "./Footer";
import Header from "./Header";
import Stage from "./Stage";
import ApplyButton from "./ApplyButton";
import { tickerHeight, tickerHeightMobile } from "../utils/theme";

const Comp = styled.div({
  position: "relative",
  overflow: "hidden",
});

const ContentPane = styled.div(({ top }) => ({
  paddingTop: `${top}px`,
  position: "relative",
  zIndex: 1,
  "@media print": {
    paddingTop: 0
  }
}));


const Content = styled.div({
  position: "relative",
  width: "100%",
  zIndex: 4
});

function Page({ children, withStage, noScrollNavigtion }) {
  const { headerHeight, isGTEDesktop, resolution, isGTETabletP } = useViewerClient();
  const footerRef = useRef(null);
  const [scrollStage, setScrollStage] = useState(false);
  const [aboveFooter, setAboveFooter] = useState(true);

  const location = useLocation();

  const handleScrollDown = useMemo(() => {
    if (window) {
      return () => {
        window.scrollTo({
          left: 0,
          top: window.innerHeight - headerHeight,
          behavior: "smooth"
        });
      };
    }
    return () => {};
  }, [headerHeight]);

  useEffect(() => {
    if (window) {
      if (location.hash) {
        if (!noScrollNavigtion) {
          const anchor = location.hash.substr(1);
          scrollToAnchor(anchor, headerHeight);
        }
      } else {
        window.scrollTo(0,0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useLayoutEffect(() => {
    if (window && withStage) {
      const handleScroll = () => {
        if (!scrollStage) {
          if (window.pageYOffset >= window.innerHeight) {
            setScrollStage(true);
          }
        } else {
          if (window.pageYOffset < window.innerHeight) {
            setScrollStage(false);
          }
        }

        if (!isGTEDesktop && footerRef.current) {
          const { y } = footerRef.current.getBoundingClientRect();
          if (y > window.innerHeight) {
            if (!aboveFooter) {
              setAboveFooter(true);
            }
          } else {
            if (aboveFooter) {
              setAboveFooter(false);
            }
          }
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  });

  // deactivate the ticker from 1.2.2023 and upon
  //const tickerDeactivationDate = new Date(2024, 1, 1);
  //const today = new Date();
  const showTicker = false;// today < tickerDeactivationDate;
  const currentTickerHeight = isGTETabletP ? tickerHeight : tickerHeightMobile
  const tickerHeightOffset = showTicker ? currentTickerHeight : 0;


  return (
    <Comp>
      <Header showTicker={showTicker}/>
      <ContentPane top={headerHeight + tickerHeightOffset}>
        {withStage && (
          <Stage onScroll={handleScrollDown}/>
        )}
        <Content id={CONTENT_ANCHOR}>{children}</Content>
      </ContentPane>
      <Footer fwRef={footerRef} />
       {!isGTEDesktop && (!withStage || scrollStage) && aboveFooter && (
        <ApplyButton resolution={resolution} />
      )}
    </Comp>
  );
}

export default Page;
