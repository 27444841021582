import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { NavLink as Link } from "react-router-dom";
import { useViewerClient, withBreakpoints } from "../context/viewerClient";
import { metaNavLinks, navLinks } from "../utils/routes";
import {
  colors,
  transitionSpeed,
  contentSpacing,
  colorSchemes,
  burgerIconHeight,
  themeButtonReset,
  hSpacing,
  fontWeight,
  fontFamily,
  fontSize,
  vSpacing
} from "../utils/theme";
import BurgerIcon from "./BurgerIcon";
import BundIcon from "./BundIcon";
import Container from "./Container";
import CrossIcon from "./CrossIcon";
import FHHIcon from "./FHHIcon";
import Logo from "./Logo";
import NavLinkItem from "./NavLinkItem";
import Newsticker from "./Newsticker";
import SponsorLogoWrapper from "./SponsorLogoWrapper";
import { useStyles } from "../context/styles";

const Comp = styled.header(({ isFixed, tickerHeight, showTicker }) => {
  const shadow = (isFixed && !showTicker)
    ? {
        boxShadow: `rgb(18 32 70 / 3%) 0px 3px 9px`
      }
    : {};

  return {
    background: colors.WHITE,
    position: isFixed ? "fixed" : "absolute",
    top: isFixed ? 0 : `${tickerHeight}px`,
    zIndex: 100,
    width: "100%",
    ...shadow,
    transition: `box-shadow ${transitionSpeed}s`,
    "@media print": {
      display: "none"
    }
  };
});

const NavSection = styled.nav(({ resolutionCoarse }) => {
  return {
    fontSize: fontSize.d40x,
    height: `${vSpacing.header[resolutionCoarse].height}px`,
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: `${vSpacing.header[resolutionCoarse].top}px ${hSpacing.page[resolutionCoarse].right}px ${vSpacing.header[resolutionCoarse].bottom}px ${hSpacing.page[resolutionCoarse].left}px`,
    transitionDuration: `${transitionSpeed}s`
  };
});

const LogoWrapper = styled.div({
  flex: "0 0 auto"
});

const NavWrapper = styled.ul(() => ({
  flex: "1 1 100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  margin: 0
}));

const NavItem = styled.li({
  listStyle: "none",
  a: {
    fontFamily: fontFamily.normal,
    fontWeight: fontWeight.regular,
    fontSize: fontSize.d40x,
    color: colors.BLACK,
    textDecoration: "none",
    paddingLeft: "24px",
    paddingRight: "24px",
    textUnderlinePosition: "under"
  },
  "a:focus": {
    textDecoration: "underline"
  },
  "a:hover": {
    textDecoration: "underline"
  }
});

const NavItemButton = styled.li({
  listStyle: "none",
  flex: "0 0 auto"
});

const NavButton = styled.a({
  fontSize: "inherit",
  fontFamily: fontFamily.normal,
  fontWeight: fontWeight.medium,
  ...colorSchemes.dark,
  textDecoration: "none",
  padding: "6px 20px 7px",
  marginLeft: "32px",
  border: `3px solid ${colorSchemes.light.color}`,
  borderRadius: "30px",
  display: 'inline-block',
  lineHeight: '30px',
  ":focus": {
    ...colorSchemes.light,
    textDecoration: "none"
  },
  ":hover": {
    ...colorSchemes.light,
    textDecoration: "none",
    ".fill": {
      fill: colorSchemes.light.color
    }
  },
  ":active": {
    ...colorSchemes.light,
    textDecoration: "none"
  }
});

const LightSpot = styled.span(withBreakpoints({
  width: '83%',
  paddingBottom: '83%',
  background: 'transparent radial-gradient(closest-side at 50% 50%, #FFFFFF80 0%, #FFFFFF4D 31%, #FFFFFF23 62%, #FFFFFF0A 86%, #FFFFFF00 100%) 0% 0% no-repeat padding-box',
  position: 'absolute',
  top: '0',
  left: '0',
  pointerEvents: 'none',
  transform: 'translateY(-50%)',
  mobile: {
    width: '100%',
  }
}))

const Overlay = styled.div({
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  overflow: "auto",
  zIndex: 10,
  transitionDuration: `${transitionSpeed}s`
});
const BurgerNavContent = styled.div(({ gradient }) => ({
  flex: "0 0 auto",
  display: "flex",
  flexDirection: "column",
  background: gradient,
  backgroundAttachment: "fixed",
  position: "relative"
}));
const PrimaryBurgerNavWrapper = styled.ul(({ resolutionCoarse, isGTETabletP, headerHeight }) => {
  return {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    fontSize: fontSize.d60x,
    lineHeight: "29px",
    paddingTop: `${headerHeight + 4}px`,
    paddingRight: `${hSpacing.page[resolutionCoarse].right}px`,
    paddingBottom: `${vSpacing.header[resolutionCoarse].bottom}px`,
    paddingLeft: `${hSpacing.page[resolutionCoarse].left + hSpacing.content[resolutionCoarse].left}px`,
    margin: `${isGTETabletP ? "50px" : "0"} 0 70px 0`,
    li: {
      marginBottom: "32px"
    }
  };
});
const SecondaryBurgerNavWrapper = styled.ul(({ resolutionCoarse }) => {
  return {
    flex: "1 1 auto",
    display: "flex",
    flexWrap: "wrap",
    fontSize: fontSize.d30x,
    lineHeight: "24px",
    paddingTop: `40px`,
    paddingRight: `${hSpacing.page[resolutionCoarse].right}px`,
    paddingBottom: `32px`,
    paddingLeft: `${hSpacing.page[resolutionCoarse].left + hSpacing.content[resolutionCoarse].left}px`,
    margin: 0,
    li: {
      marginRight: `${hSpacing.colGap[resolutionCoarse]}px`
    },
    "li:last-child": {
      marginRight: 0
    }
  };
});
const BurgerNavFooter = styled.div(({ resolution }) => ({
  flex: "1 0 auto",
  background: colors.WHITE,
  padding: `40px ${contentSpacing[resolution]}`,
  paddingTop: "40px",
  ".fill": {
    fill: colorSchemes.light.color
  }
}));
const BurgerNavFooterContent = styled.div({
  display: "flex"
});

const BurgerWrapper = styled.div(({ height }) => ({
  ...themeButtonReset,
  display: "flex",
  alignItems: "center",
  height,
  position: "relative"
}));

const OverlayIconWrapper = styled.div(({ height, tickerHeight, resolutionCoarse }) => ({
  ...themeButtonReset,
  height,
  position: "absolute",
  right: `${hSpacing.page[resolutionCoarse].right}px`,
  top: `${vSpacing.header[resolutionCoarse].top + tickerHeight}px`
}));

const BurgerButton = styled.button({
  ...themeButtonReset,
  height: "100%",
  zIndex: 11,
  svg: {
    height: "100%",
    width: "auto"
  }
});

function Header({showTicker}) {
  const { t } = useTranslation();
  const { isGTETabletP, isGTEDesktop, resolution, resolutionCoarse, tickerHeight, headerHeight } = useViewerClient();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const { gradient } = useStyles();

  useLayoutEffect(() => {
    if (window) {
      const handleScroll = () => {
        if (!isFixed) {
          if (window.pageYOffset > tickerHeight) {
            setIsFixed(true);
          }
        } else {
          if (window.pageYOffset <= tickerHeight) {
            setIsFixed(false);
          }
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [isFixed, tickerHeight]);

  return (
    <>
      <Comp isFixed={isFixed} tickerHeight={tickerHeight} showTicker={showTicker}>
        <Container theme="transparent">
          <NavSection resolution={resolution} resolutionCoarse={resolutionCoarse}>
            <LogoWrapper>
              <a href="/" title={t("header.home")}>
                <Logo isSmall={!isGTEDesktop} />
              </a>
            </LogoWrapper>
            {isGTEDesktop ? (
              <NavWrapper resolution={resolution}>
                {navLinks.map(({ key, path, hash }) => (
                  <NavItem key={`nav_${key}`}>
                    <Link
                      to={`${path ? `/${path}` : ""}${hash ? `/#${hash}` : ""}`}
                      activeStyle={{
                        textDecoration: "underline",
                        fontWeight: fontWeight.medium
                      }}
                      strict
                      exact
                    >
                      {t(`nav.${key}`)}
                    </Link>
                  </NavItem>
                ))}
                <NavItemButton key={`nav_button`}>
                  <NavButton href={`${process.env.REACT_APP_APPLY_LINK}`} target="_blank">
                    {t("link.apply.text")}
                  </NavButton>
                </NavItemButton>
              </NavWrapper>
            ) : (
              <>
                {isMenuOpen ? (
                  <Overlay>
                    <BurgerNavContent resolution={resolution} gradient={gradient}>
                      <LightSpot resolutionCoarse={resolutionCoarse} />
                      <PrimaryBurgerNavWrapper
                        resolution={resolution}
                        resolutionCoarse={resolutionCoarse}
                        isGTETabletP={isGTETabletP}
                        headerHeight={headerHeight}
                      >
                        {navLinks.map(({ key, path, hash }) => (
                          <NavLinkItem key={`b_nav_${key}`} colorScheme={colorSchemes.dark}>
                            <Link
                              to={`${path ? `/${path}` : ""}${hash ? `/#${hash}` : ""}`}
                              activeStyle={{
                                textDecoration: "underline",
                                fontWeight: fontWeight.medium
                              }}
                              strict
                              exact
                            >
                              {t(`nav.${key}`)}
                            </Link>
                          </NavLinkItem>
                        ))}
                      </PrimaryBurgerNavWrapper>
                      <OverlayIconWrapper
                        height={burgerIconHeight}
                        resolutionCoarse={resolutionCoarse}
                        tickerHeight={isFixed ? 0 : tickerHeight}
                      >
                        <BurgerButton onClick={() => setIsMenuOpen(false)} ariaLabel={t("nav.menuClose")}>
                          <CrossIcon color={colorSchemes.dark.color} />
                        </BurgerButton>
                      </OverlayIconWrapper>
                      <SecondaryBurgerNavWrapper resolutionCoarse={resolutionCoarse}>
                        {metaNavLinks.map(({ key, path, hash }) => (
                          <NavLinkItem key={`b_nav_${key}`} colorScheme={colorSchemes.dark}>
                            <Link
                              to={`${path ? `/${path}` : ""}${hash ? `/#${hash}` : ""}`}
                              activeStyle={{
                                textDecoration: "underline"
                              }}
                            >
                              {t(`nav.${key}`)}
                            </Link>
                          </NavLinkItem>
                        ))}
                      </SecondaryBurgerNavWrapper>
                    </BurgerNavContent>
                    <BurgerNavFooter resolution={resolution}>
                      <BurgerNavFooterContent>
                        <SponsorLogoWrapper isFirst={true}>
                          <BundIcon />
                        </SponsorLogoWrapper>
                        <SponsorLogoWrapper>
                          <FHHIcon />
                        </SponsorLogoWrapper>
                      </BurgerNavFooterContent>
                    </BurgerNavFooter>
                  </Overlay>
                ) : (
                  <BurgerWrapper height={burgerIconHeight}>
                    <BurgerButton onClick={() => setIsMenuOpen(true)} ariaLabel={t("nav.menuOpen")}>
                      <BurgerIcon />
                    </BurgerButton>
                  </BurgerWrapper>
                )}
              </>
            )}
          </NavSection>
        </Container>
        {showTicker && (
          <Newsticker>
            {t("header.tickerContent")}
          </Newsticker>
        )}
      </Comp>
    </>
  );
}

export default Header;
