
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from "../locales/de/labels";

i18n
.use(initReactI18next)
.init({
  lng: "de",
  debug: process.env.NODE_ENV === "development",
  resources: {
    de: {
      translation: de
    }
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "b", "u"]
  }
});

export default i18n;