import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { scrollToAnchor } from "../utils/content";
import {
  fontStyleAccordion,
  colorSchemes
} from "../utils/theme";
import AccordionSection from "./AccordionSection";

const Comp = styled.div(({ indent, level, resolutionCoarse }) => {
  const styles = {};
  if (indent) {
    if (resolutionCoarse === "desktop") {
      // use styles from level 0 here, so 2.5ch has exactly the same width as the tab stops
      const { fontSize, fontWeight } = fontStyleAccordion[0][resolutionCoarse];
      styles.fontSize = fontSize;
      styles.fontWeight = fontWeight;
      styles.paddingLeft = (resolutionCoarse === "desktop") ? "2.5ch" : "0px";
    } else {
      styles.paddingLeft = "0px";
    }
  }
  if (level === 1) {
    styles.borderTop = `4px solid ${colorSchemes.light.color}`;
  }
  return styles;
});

const Accordion = ({ sections: inputSections, prefix, level, openMulti = false, useFilter = false, contentPath }) => {
  const { isGTETabletP, resolutionCoarse, headerHeight } = useViewerClient();
  const [expandedSection, setExpandedSection] = useState(null);
  // set initially selected tab base on hash
  const history = useHistory();
  const { location } = history;
  const { hash } = location;

  const scrollOffset = headerHeight + 30;

  const sections = useFilter ? inputSections.filter(({ matchCount }) => !!matchCount) : inputSections;

  useEffect(() => {
    if (hash) {
      const sectionId = hash.substr(1);
      // expand section if subsection is initially expanded
      const initialSection = sections.find(
        ({ anchor, sections }) =>
          anchor === sectionId || !!(sections && sections.find(({ anchor }) => anchor === sectionId))
      );
      if (initialSection && expandedSection !== initialSection.anchor) {
        setExpandedSection(initialSection.anchor);
        setTimeout(() => scrollToAnchor(initialSection.anchor, scrollOffset), 10);
      }
    }
  }, [expandedSection, hash, scrollOffset, sections]);

  const scrollTo = useCallback((key) => scrollToAnchor(key, scrollOffset),[scrollOffset]);

  const toggle = useCallback(
    (key) => {
      if (expandedSection === key) {
        setExpandedSection(null);
        if (location.hash) {
          location.hash = null;
        }
      } else {
        if (expandedSection) {
          setTimeout(() =>  {
            scrollToAnchor(key, scrollOffset, "auto");
            setTimeout(() =>  {
              setExpandedSection(key);
            }, 20);
          }, 500);
        }
        setExpandedSection(null);
        location.hash = `#${key}`;
        history.push({ ...location });
      }
    },
    [expandedSection, history, location, scrollOffset]
  );

  return (
    <Comp indent={isGTETabletP && level} resolutionCoarse={resolutionCoarse} level={level}>
      {sections.map(({ anchor, ...sectionsContent }, index) => {
        const key = anchor || `${prefix}_${index}`;
        const isExpanded = expandedSection === key;
        return (
          <AccordionSection
            sectionKey={key}
            noBorder={level && index === sections.length - 1}
            isExpanded={isExpanded}
            level={level}
            toggle={toggle}
            scrollTo={scrollTo}
            openMulti={openMulti}
            useFilter={useFilter}
            {...sectionsContent}
            contentPath={contentPath}
            key={sectionsContent.id || key}
          />
        );
      })}
    </Comp>
  );
};

export default Accordion;
