import React from "react";
import styled from "styled-components";
import {
  backgrounds,
  containerWidthsIndent,
  containerWidthsTabs,
  containerWidthsText,
  containerPaddingsText,
  containerPaddingsTopText,
  containerPaddingsBox,
  containerPaddingsTabs,
  containerPaddingsTextProcess
} from "../utils/theme";
import { useViewerClient } from "../context/viewerClient";
import Container from "./Container";
import Section from "./Section";

import ArrowRightIcon from "./ArrowRightIcon";

const Comp = styled.div(({ styles, isPrint }) => {
  const printStyles = isPrint ? {
    "@media print": {
      display: "none"
    }
  } : {};
  return {
    ...printStyles,
    ...styles
  }
});

/**
 * 
 * @param {object} content content object
 * @param {string} prefix prefix for id and child elements without anchors
 * @param {string} type one out of intro, box, content, cta
 * @param {object} styles additional styles
 * @param {boolean} isPrint if true, use print styles
 * @param {function} contentProvider content provider function
 * @returns 
 */
const Block = ({ content, prefix, theme, type, styles, isPrint, contentProvider }) => {
  const { resolution } = useViewerClient();

  const containerProps = {};
  let spacing = {};

  let background = backgrounds[(theme || "white")];

  switch(type) {
    case "faqAbstract":
      containerProps.contentSpacings = containerWidthsText;
      spacing = containerPaddingsTopText[resolution];
      break;
    case "intro":
      containerProps.contentSpacings = containerWidthsText;
      spacing = containerPaddingsText[resolution];
      content[0].decorator = ArrowRightIcon;
      background = "#ffc4c4";
      break;
    case "prc":
      containerProps.contentSpacings = containerWidthsIndent;
      spacing = containerPaddingsTextProcess[resolution];
      break;
    case "box":
      containerProps.contentSpacings = containerWidthsText;
      spacing = containerPaddingsBox[resolution];
      break;
    case "tabs":
      containerProps.contentSpacings = containerWidthsTabs;
      spacing = containerPaddingsTabs[resolution];
      break;
    default:
      containerProps.contentSpacings = containerWidthsText;
      spacing = containerPaddingsText[resolution];
      break;
  }

  return (
    <Comp styles={{ ...spacing, background, ...styles }} isPrint={isPrint}>
      {content.map(({ decorator, ...section}, index) => {
        const key = `${prefix}_${index}`;
        return (
          <Container fullWidth {...containerProps} decorator={decorator} key={key}>
            <Section section={section} prefix={key} contentProvider={contentProvider}/>
          </Container>
        );
      })}
    </Comp>
  );
};

export default Block;