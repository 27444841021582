import React from "react";
import Page from "../components/Page";
import Block from "../components/Block";
import content from "../locales/de/content";
import { useVersionedContent } from "../context/versionedContent";
import PrintPage from "../components/PrintPage";

const Faq = () => {
  const { modes } = useVersionedContent();
  let theme;
  if (modes.faq === "compare") {
    theme = "yellow";
  } else if (modes.faq === "search") {
    theme = "blue";
  } else {
    theme = "strong_grey";
  }
  const isPrint = modes.faq === "print";
  return (
    <Page noScrollNavigtion isPrint={isPrint}>
      <Block content={content.faqIntro} prefix="faq" isPrint={isPrint} type="faqAbstract" />
      <Block
        content={content.faqFilter}
        contentProvider={useVersionedContent}
        prefix="faq"
        type="tabs"
        theme={theme}
        isPrint={isPrint}
        styles={{ marginBottom: "96px" }}
      />
      {isPrint ? (
        <>
          <PrintPage prefix="faq" />
        </>
      ) : (
        <Block
          content={content.faqAccordion}
          prefix="faq"
          contentProvider={useVersionedContent}
          styles={{ paddingTop: "22px" }}
        />
      )}
      <Block
        content={content.contactFaq}
        prefix="contact"
        type="cta"
        theme="grey"
        isPrint={isPrint}
        styles={{ marginTop: "16px" }}
      />
    </Page>
  );
};

export default Faq;
