import React from "react";
import styled from "styled-components";
import { useStyles } from "../context/styles";


const StagePatternInner = styled.div(({ gradient }) => ({
  background: gradient,
  width: "100%",
  height: "100%"
}));

function StagePattern() {
  const { gradient } = useStyles();

  return (
    <StagePatternInner gradient={gradient}/>
  );
}

export default StagePattern;
