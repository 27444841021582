import React from "react";
import { colorSchemes } from "../utils/theme";

function SearchIcon({ color = colorSchemes.light.color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.235" height="26.235" viewBox="0 0 26.235 26.235">
      <path
        d="M23.25,21H22.065l-.42-.405a9.765,9.765,0,1,0-1.05,1.05l.405.42V23.25l7.5,7.485L30.735,28.5Zm-9,0A6.75,6.75,0,1,1,21,14.25,6.741,6.741,0,0,1,14.25,21Z"
        transform="translate(-4.5 -4.5)"
        fill={color}
      />
    </svg>
  );
}

export default SearchIcon;
