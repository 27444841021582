import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colors, fontSize, fontWeight, themeButtonReset } from "../utils/theme";

const Comp = styled.div({
  position: "relative"
});

const ExpandOverlay =styled.div(() => ({
  background: `linear-gradient(to bottom, rgba(255, 255, 255, 0), ${colors.WHITE} 100%)`,
  width: "100%",
  height: "180px",
  position: "absolute",
  bottom: 0,
  left: 0
}));

const ExpandButton = styled.button(() => ({
  ...themeButtonReset,
  marginBlockStart: "16px",
  float: "right"
}));

const Span = styled.span(() => ({
  fontSize: fontSize.d30x,
  fontWeight: fontWeight.semiBold
}));

const Expandable = ({ children }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Comp>
      {!isExpanded && (
        <>
          <ExpandButton onClick={() => setIsExpanded(true)}>
            <Span>{t("common.expand")}</Span>
          </ExpandButton>
          <ExpandOverlay />
        </>
      )}
      {isExpanded && children}
    </Comp>
  );
};

export default Expandable;
