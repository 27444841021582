import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { hSpacing, vSpacing } from "../utils/theme";
import Expandable from "./Expandable";
import H3 from "./H3";
import Paragraph from "./Paragraph";

const Comp = styled.div(({ resolutionCoarse, count, showInColums, styles }) => {
  if (showInColums) {
    return {
      flex: `0 1 ${100 / count}%`,
      marginTop: `${vSpacing.container[resolutionCoarse].top}px`,
      paddingLeft: `${hSpacing.colGap[resolutionCoarse] / 2}px`,
      paddingRight: `${hSpacing.colGap[resolutionCoarse] / 2}px`,
      ...styles
    }
  }
  return styles;
});

const Column = ({ count = 1, showInColums, content, columnStyles, isExpanded = true }) => {
  const { resolutionCoarse } = useViewerClient();
  const { headline, paragraphs, collapsedParagraphs, anchor } = content;
  const compProps = {};
  if (anchor) {
    compProps.id = anchor;
  }

  return (
    <Comp
      resolutionCoarse={resolutionCoarse}
      count={count}
      showInColums={showInColums}
      styles={columnStyles}
      {...compProps}
    >
      {headline && (
        <H3>{headline}</H3>
      )}
      {paragraphs && paragraphs.map((paragraph, index) => {
        const key = `p_${index}`;
        return (
          <Paragraph key={key} isExpanded={isExpanded} {...paragraph} />
        );
      })}
      {collapsedParagraphs && (
        <Expandable>
          {collapsedParagraphs.map((paragraph, index) => {
            const key = `p_${index}`;
            return (
              <Paragraph key={key} isExpanded={isExpanded} {...paragraph} />
            );
          })}
        </Expandable>
      )}
    </Comp>
  );
};

export default Column;
