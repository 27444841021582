import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { useStyles } from "../context/styles";
import { useViewerClient } from "../context/viewerClient";
import { colorSchemes, contentSpacing, fontFamily } from "../utils/theme";
import AddPersonIcon from "./AddPersonIcon";
import DocumentIcon from "./DocumentIcon";
import Triangle from "./Triangle";
import UploadIcon from "./UploadIcon";

const baseStep = 720;
const baseEvent = 300;
const boxHeight = 120;
const triangleWidth = 12;
const triangleHeight = 12;

const Comp = styled.div(({ resolution, isGTETabletP }) => {
  if (resolution === "tabletP") {
    return {
      marginLeft: `-${contentSpacing.tabletP}`,
      marginRight: `-${contentSpacing.tabletP}`,
      display: "flex"
    };
  }
  else {
    return {
      display: "flex",
      flexDirection: isGTETabletP ? "row" : "column"
    }
  }
});

const Headline = styled.div(({ styles }) => {
  return {
    fontFamily: fontFamily.mono,
    fontSize: "19px",
    lineHeight: "36px",
    marginBottom: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    svg: {
      marginLeft: "16px",
      flex: "0 0 42px",
      verticalAlign: "middle"
    },
    ...styles
  };
});

const StepWrapperPre = styled.div(({ isGTETabletP, base}) => ({
  display: "flex",
  flex: isGTETabletP ? `1 1 ${base}px` : "none",
  flexDirection: "column",
  width: isGTETabletP ? "auto" :"280px",
  marginBottom: isGTETabletP ? "0" :"35px",
}));

const TriangleWrapper = styled.div(({ styles }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  ...styles
}));

const StepWrapperAfter = styled.div(({ isGTETabletP, base}) => ({
  display: "flex",
  flex: isGTETabletP ? `1 1 ${base}px` : "none",
  flexDirection: "column",
  marginLeft: isGTETabletP ? "15px" : "0",
  width: isGTETabletP ? "auto" :"280px"
}));


const Step = styled.div(() => ({
  display: "block"
}));

const StepPre = styled.div(({ isGTETabletP } ) => ({
  display: "flex",
  flexDirection: isGTETabletP ? "row" : "column"
}));

const StepBox = styled.div(({ isGTETabletP }) => ({
  height: isGTETabletP ? `${boxHeight}px` : "140px",
  display: "flex",
  flexDirection: isGTETabletP ? "row" : "column",
  minWidth: "100%",
  svg: {
    display: "block",
    height: "100%"
  }
}));

const StepBoxIcon = styled.div(({ isGTETabletP, justifyContent = "center" }) => ({
  height: isGTETabletP ? `${boxHeight}px` : "48px",
  display: "flex",
  justifyContent,
  minWidth: "100px",
  svg: {
    display: "block",
    height: "100%"
  }
}));

const StepBoxWrapper = styled.div(({ isGTETabletP, gradientProcess }) => ({
  height: isGTETabletP ? `${boxHeight}px` : "100%",
  display: "flex",
  flexDirection: isGTETabletP ? "row" : "column",
  background: gradientProcess,
  justifyContent: isGTETabletP ? "space-around" : "center",
  paddingTop: isGTETabletP ? "0" : "40px",
  width: "100%"
}));

const LabelWrapper = styled.div(({ isGTETabletP, justifyContent = "space-around" }) => ({
  height: `${boxHeight}px`,
  display: isGTETabletP ? "flex" : "none",
  justifyContent
}));

const ProcessText = styled.div(() => ({
  fontFamily: fontFamily.mono,
  fontSize: "19px",
  lineHeight: "33px",
  flex: "1 1 100%",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: "20px",
  paddingRight: "20px",
  ...colorSchemes.dark
}));

const Label = styled.div(({ isGTETabletP, styles }) => {
  return {
    display: isGTETabletP ? "block" : "none",
    fontFamily: fontFamily.mono,
    fontSize: "17px",
    lineHeight: "24px",
    marginTop: "16px",
    paddingLeft: "10px",
    paddingRight: "10px",
    textAlign: "center",
    ...styles
  };
});

const LabelMobile = styled.div(({ isGTETabletP, styles }) => {
  return {
    display: isGTETabletP ? "none" : "block",
    fontFamily: fontFamily.mono,
    fontSize: "17px",
    lineHeight: "24px",
    marginBottom: "60px",
    marginTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    textAlign: "center",
    ...styles
  };
});

function ProcessStepDesktop() {
  const { t } = useTranslation();
  const { resolutionCoarse, resolution, isGTETabletP } = useViewerClient();
  const { gradientProcess } = useStyles();

  const headlineLeftStyles = {};
  const headlineRightStyles = {};

  if (resolution === "tabletP") {
    // TODO use variables when implementing complete tablet layout
    headlineLeftStyles.paddingLeft = "16px";
    headlineRightStyles.paddingRight = "16px";
  } else if (resolution === "tabletL") {
    headlineRightStyles.paddingRight = "32px";
  } else {
    headlineLeftStyles.paddingLeft = "32px";
    headlineRightStyles.paddingLeft = "32px";
    headlineRightStyles.paddingRight = "32px";
  }

  return (
    <Comp resolution={resolution} resolutionCoarse={resolutionCoarse} isGTETabletP={isGTETabletP}>
      <StepWrapperPre isGTETabletP={isGTETabletP} base={baseEvent}>
        <Step>
          <Headline resolutionCoarse={resolutionCoarse}>
            {t(`process.pre.headline`)}
          </Headline>
        </Step>
        <StepPre isGTETabletP={isGTETabletP}>
          <StepBox isGTETabletP={isGTETabletP} background={colorSchemes.dark.background} justifyContent="start">
            <ProcessText resolutionCoarse={resolutionCoarse}>{t(`process.event`)}</ProcessText>
            {isGTETabletP ? (
              <Triangle
                orientation="left"
                height={`${triangleWidth}px`}
                semiBase="10px"
                color={colorSchemes.dark.background}
              />
            ) : (
              <TriangleWrapper base={triangleHeight}>
                <Triangle
                  orientation="top"
                  height={`${triangleHeight}px`}
                  semiBase="10px"
                  color={colorSchemes.dark.background}
                />
              </TriangleWrapper>
            )}
          </StepBox>
        </StepPre>
      </StepWrapperPre>
      <StepWrapperAfter isGTETabletP={isGTETabletP} base={baseStep}>
        <Step>
          <Headline styles={headlineRightStyles} resolutionCoarse={resolutionCoarse}>
            {t(`process.after.headline`)}
          </Headline>
        </Step>
        <Step>
          <StepBoxWrapper isGTETabletP={isGTETabletP} gradientProcess={gradientProcess}>
            <StepBoxIcon isGTETabletP={isGTETabletP}>
              <AddPersonIcon />
            </StepBoxIcon>
            <LabelMobile isGTETabletP={isGTETabletP}>
              <Trans i18nKey={`process.after.text1`}>...</Trans>
            </LabelMobile>
            <StepBoxIcon isGTETabletP={isGTETabletP}>
              <DocumentIcon />
            </StepBoxIcon>
            <LabelMobile isGTETabletP={isGTETabletP}>
              <Trans i18nKey={`process.after.text2`}>...</Trans>
            </LabelMobile>
            <StepBoxIcon isGTETabletP={isGTETabletP}>
              <UploadIcon />
            </StepBoxIcon>
            <LabelMobile isGTETabletP={isGTETabletP}>
              <Trans i18nKey={`process.after.text3`}>...</Trans>
            </LabelMobile>
          </StepBoxWrapper>
          <LabelWrapper isGTETabletP={isGTETabletP}>
            <Label isGTETabletP={isGTETabletP}>
              <Trans i18nKey={`process.after.text1`}>...</Trans>
            </Label>
            <Label isGTETabletP={isGTETabletP}>
              <Trans i18nKey={`process.after.text2`}>...</Trans>
            </Label>
            <Label isGTETabletP={isGTETabletP}>
              <Trans i18nKey={`process.after.text3`}>...</Trans>
            </Label>
          </LabelWrapper>
        </Step>
      </StepWrapperAfter>
    </Comp>
  );
}

export default ProcessStepDesktop;
