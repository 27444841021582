/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import Ticker from "react-ticker";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { colors, themeTicker, tickerHeight, tickerHeightMobile } from "../utils/theme";

const Comp = styled.div(({ resolutionCoarse }) => ({
  width: "100%",
  color: colors.WHITE,
  background: colors.BLACK,
  ...themeTicker[resolutionCoarse]
}));

const TickerItem = styled.div({
  whiteSpace: "nowrap"
});

function Newsticker({ children }) {
  const { resolutionCoarse, isGTETabletP } = useViewerClient();
  const [fontsLoaded, setFontsLoaded] = useState(false);
  document.fonts.ready.then(() => setFontsLoaded(true));

  if (!fontsLoaded) {
    return null;
  }

  return (
    <Comp resolutionCoarse={resolutionCoarse}>
      <Ticker height={isGTETabletP ? tickerHeight : tickerHeightMobile } offset="-60%">
        {() => <TickerItem>{children}&nbsp;&nbsp;◗●◖&nbsp;&nbsp;</TickerItem>}
      </Ticker>
    </Comp>
  );
}

export default Newsticker;
