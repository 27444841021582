import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { parseContent } from "../utils/content";
import {
  colors,
  fontStyleText,
  nestedListIndent,
  secondNestedListIndent,
  linkStyles,
  containerTextBox,
  containerExpandableTextBox,
  containerTextBoxIndent,
  diffStyles,
  fontWeight,
  vSpacing
} from "../utils/theme";
import Column from "./Column";
import H4 from "./H4";
import PDFIcon from "./PDFIcon";
import TextBox from "./TextBox";

const Comp = styled.div(({ resolution, resolutionCoarse }) => {
  return {
    // marginTop: `${vSpacing.text[resolutionCoarse]}px`,
    ...fontStyleText[resolutionCoarse],
    ...linkStyles,
    p: {
      marginBlockStart: 0,
      marginBlockEnd: `${vSpacing.text[resolutionCoarse]}px`,
    },
    "ul li": {
      fontWeight: fontWeight.medium,
      marginTop: '20px',
      marginBottom: '20px'
    },
    "ol li": {
      fontWeight: fontWeight.medium,
      marginTop: '20px',
      marginBottom: '20px'
    },
    ul: ul[resolutionCoarse],
    "ul ul": {
      paddingInlineStart: (resolutionCoarse === "desktop") ? "40px" : "0px",
      paddingLeft: nestedListIndent[resolution],
      listStyleType: "none"
    },
    "ul ul ul": {
      listStyleType: "none",
      paddingLeft: secondNestedListIndent[resolution]
    },
    "ul ul ul ul li::before": {
      content: "none"
    },
    li: li[resolutionCoarse],
    "li li": {
      fontWeight: fontStyleText[resolutionCoarse].fontWeight
    },
    "li li::before": {
      content: "\"-\"",
      marginLeft: "-19px",
      paddingRight: "9px"
    },
    "li li li::before": {
      content: "'\\00B7'",
      marginLeft: "-16px"
    },
    ...diffStyles
  };
});

const ul = {
  mobile: {
    paddingInlineStart: "19px",
  },
  tablet: {
    paddingInlineStart: "19px",
  }
}

const li = {
  mobile: {
    fontWeight: fontWeight.medium,
    paddingInlineStart: 0,
  },
  tablet: {
    fontWeight: fontWeight.medium,
    paddingInlineStart: 0,
  },
  desktop: {
    fontWeight: fontWeight.medium
  }
}

const Box = styled.div(({ resolution, resolutionCoarse, isExpandable, indent = 0 }) => {
  let indentStlyes = {};
  if (containerTextBoxIndent[resolutionCoarse] && containerTextBoxIndent[resolutionCoarse][`${indent}`]) {
    indentStlyes = containerTextBoxIndent[resolutionCoarse][`${indent}`];
  }
  const containerStyles = isExpandable ? containerExpandableTextBox[resolution] : containerTextBox[resolutionCoarse];
  return {
    background: colors.GREY_LIGHT,
    ...containerStyles,
    ...indentStlyes,
    li: {
      fontWeight: fontStyleText[resolutionCoarse].fontWeight
    }
  };
});

const FileLink = styled.a({});

const Paragraph = ({ headline, text, anchor, type, sections, indent, media, isExpanded = true}) => {
  const { resolution, resolutionCoarse } = useViewerClient();
  const compProps = {};
  if (anchor) {
    compProps.id = anchor;
  }

  if (type === "textbox") {
    if (sections) {
      // expandable textbox with subsections and headline
      return (
        <Box resolution={resolution} resolutionCoarse={resolutionCoarse} isExpandable indent={indent} {...compProps}>
          {sections.map((section, index) => (
            <TextBox {...section} key={`textBox_${index}`} />
          ))}
        </Box>
      );
    } else {
      // simple textbox
      return (
        <Box resolution={resolution} resolutionCoarse={resolutionCoarse} indent={indent} {...compProps}>
          <Column content={{ paragraphs: [{ headline, text }] }} />
        </Box>
      );
    }
  }
  return (
    <Comp resolution={resolution} resolutionCoarse={resolutionCoarse} {...compProps}>
      {headline && <H4>{parseContent(headline)}</H4>}
      {text && parseContent(text, isExpanded)}
      {media && media
        .filter(({ mime }) => mime === "application/pdf")
        .map((m) => (
          <FileLink className="file-link" href={m.url} target="_blank" key={m.hash}>{parseContent(m.text)}<PDFIcon /></FileLink>
        ))
      }
    </Comp>
  );
};

export default Paragraph;
